import type { RouterConfig } from '@nuxt/schema'

export default <RouterConfig>{
  scrollBehavior (to, from, savedPosition) {
    const nuxtApp = useNuxtApp()

    // If history back
    if (savedPosition) {
      // Handle Suspense resolution
      return new Promise((resolve) => {
        nuxtApp.hooks.hookOnce('page:finish', () => {
          setTimeout(() => resolve(savedPosition), 50)
        })
      })
    }

    // Scroll to heading on click
    if (to.hash) {
      setTimeout(() => {
        const heading = document.querySelector(to.hash) as any

        return window.scrollTo({
          top: heading?.offsetTop || 0,
          behavior: 'smooth'
        })
      })
      return
    }

    if (from.path === to.path) {
      if (from.query?.page !== to.query?.page && !to.query?.page?.includes('-')) {
        setTimeout(() => {
          const container = document.querySelector('.app-page__content') as any
          return window.scrollTo({
            top: container.offsetTop - 100,
          })
        })
      } else {
        return {}
      }
    }

    // Scroll to top of window
    return new Promise((resolve) => {
      nuxtApp.hooks.hookOnce('page:finish', () => {
        setTimeout(() => resolve({ top: 0 }), 300)
      })
    })
  }
}
