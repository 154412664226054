<script setup lang="ts">
  import { computed, useI18n, useLocalePath } from '#imports'

  const { locale, locales } = useI18n()
  const switchLocalePath = useSwitchLocalePath()
  const availableLocales = computed(() => locales.value?.filter((l) => l.code !== locale.value))
</script>

<template>
  <div class="language-switcher">
    <app-button
      v-for="option in availableLocales"
      :key="option.code"
      :to="option.domain"
      class="language-switcher__button"
      :title="option.longName"
      external
      square
      light>
      {{ option.name }}
    </app-button>
  </div>
</template>

<style scoped lang="postcss">
  .language-switcher__button {
    aspect-ratio: 1/1;
    box-sizing: border-box;
    @media (--xs) {
      width: var(--gap-500);
      height: var(--gap-500);
    }
  }
</style>
