<script setup lang="ts">
  const props = defineProps<{
    error?: boolean
    success?: boolean
    type?: 'error' | 'success' | 'info' | 'warning' | 'dark'
    dismissable?: boolean
    fullWidth?: boolean
    compact?: boolean
  }>()

  const isVisible = ref(true)

  const alertType = computed(() => {
    if (props.error) return 'error'
    if (props.success) return 'success'
    return props.type || 'info'
  })

  const iconMapping = {
    error: 'mdi:exclamation',
    success: 'mdi:check',
    info: 'mdi:information-variant',
    warning: 'mdi:exclamation',
  }

  const dismiss = () => {
    isVisible.value = false
  }
</script>

<template>
  <div v-auto-animate>
    <div
      v-if="isVisible"
      :class="['app-alert', `app-alert--${alertType}`, { 'app-alert--compact': compact }]">
      <component
        :is="fullWidth ? 'app-container' : 'div'"
        class="app-alert__container">
        <div class="app-alert__layout">
          <div
            v-if="!compact"
            class="app-alert__icon">
            <Icon :name="iconMapping[alertType]"/>
          </div>
          <div class="app-alert__content">
            <slot/>
          </div>

          <Icon
            v-if="dismissable"
            @click.native="dismiss"
            name="mdi:close"
            class="app-alert__dismiss"/>
        </div>
      </component>
    </div>
  </div>
</template>

<style scoped>
  .app-alert {
    color: var(--c-background-light);
    background: var(--c-info);
    padding: var(--gap-200) var(--gap-300);
    font-size: var(--font-size-300);
    &:deep(a) {
      &,
      &:visited {
        color: var(--c-background-light);
      }
    }
  }

  .app-alert__layout {
    display: flex;
    align-items: center;
  }

  .app-alert--error {
    background: var(--c-error);
  }

  .app-alert--success {
    background: var(--c-success);
  }

  .app-alert--dark {
    background: var(--c-black);
  }

  .app-alert--warning {
    background: var(--c-warning);
    color: var(--c-black);
    & .app-alert__icon {
      border-color: var(--c-black);
    }

    &:deep(a) {
      &,
      &:visited {
        color: var(--c-black);
      }
    }
  }

  .app-alert__icon {
    border: var(--gap-50) solid var(--c-background-light);
    border-radius: 50%;
    width: var(--gap-500);
    height: var(--gap-500);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: var(--gap-400);
    flex-shrink: 0;

    &:deep(svg) {
      width: 1.2em;
      height: 1.2em;
    }
  }

  .app-alert__content {
    line-height: 1.1;
    flex-grow: 0;
    margin-right: auto;
  }

  .app-alert__dismiss {
    cursor: pointer;
  }

  .app-alert--compact {
    padding: var(--gap-250) var(--gap-300);
  }
</style>
