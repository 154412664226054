<script setup lang="ts">
  import type { FormKitContext } from '@formkit/core'
  import { formHelpers } from '~/utils/form-helpers'
  import { token } from '@formkit/utils'

  const props = defineProps<{ context: FormKitContext }>()

  const inputValue = ref(props.context._value)

  const items = ref(inputValue.value
    ? inputValue.value.map(() => token())
    : [token()])

  function addRow () {
    items.value.push(token())
  }

  async function removeRow (id) {
    items.value = items.value.filter(item => item !== id)
    handleInput()
  }

  function handleInput () {
    props.context.node.input(inputValue.value)
  }
</script>

<template>
  <div class="input-repeater">
    <FormKit
      type="list"
      :id="context.id"
      :name="context.id"
      v-model="inputValue">
      <div v-auto-animate="{ duration: 150 }">
        <template
          v-for="(id, key) in items"
          :key="id">
          <FormKit type="group">
            <div class="input-repeater__row">
              <FormKitSchema
                :data="{ ...inputValue?.[key], ...formHelpers }"
                :schema="context.schema"/>
              <div class="input-repeater__controls">
                <mdicon
                  class="input-repeater__remove"
                  name="close"
                  :style="{
                    opacity: key > 0 ? 1 : 0,
                    pointerEvents: key > 0 ? 'auto' : 'none',
                  }"
                  @click="removeRow(id)"/>
              </div>
            </div>
          </FormKit>
        </template>
      </div>
      <app-button
        @click="addRow"
        text
        type="button">
        {{ context.addButtonText || 'Přidat další řádek' }}
      </app-button>
    </FormKit>
  </div>
</template>

<style scoped>
  .input-repeater__row {
    display: flex;
    gap: var(--gap-300);
    flex-wrap: wrap;
    flex-direction: column;
    position: relative;
    margin-bottom: var(--gap-500);
    &:deep(.is-wide) {
      width: 50%;
      & .app-form__inner {
        width: 100%;
      }
    }
    @media (--md) {
      margin-bottom: 0;
      flex-direction: row;
      flex-wrap: nowrap;
    }
    &:deep(.app-form__wrapper) {
      @media (--md) {
        display: flex;
        align-items: center;
      }
    }
    &:deep(.app-form__outer) {
      margin-bottom: var(--gap-100) !important;
      @media (--md) {
        margin-bottom: var(--gap-300) !important;
      }
      width: 100%;
    }
    &:deep(.app-form__label) {
      @media (--md) {
        white-space: nowrap;
        padding-left: var(--gap-400);
        padding-right: var(--gap-200);
      }
    }
  }

  .input-repeater__remove {
    cursor: pointer;
    color: var(--c-primary);
  }

  .input-repeater__controls {
    width: 48px;
    margin-top: var(--gap-200);
    display: flex;
    justify-content: flex-end;

    position: absolute;
    right: -48px;
    top: 0;

    @media (--md) {
      position: relative;
      right: auto;
      top: auto;
    }
  }

  .input-repeater__row :deep(input) {
    width: 100%;
    min-width: calc(6ch + (var(--gap-300) * 2));
  }
</style>
