import InputRange from '~/components/InputRange.vue'
// import InputDateTimePicker from '~/components/InputDateTimePicker.vue'
import InputRepeater from '~/components/InputRepeater.vue'
import InputHint from '~/components/InputHint.vue'
import InputImage from '~/components/InputImage.vue'
import InputMap from '~/components/InputMap.vue'
import { DefaultConfigOptions, createInput  } from '@formkit/vue'
import { createAutoAnimatePlugin } from '@formkit/addons'
import { cs, en } from '@formkit/i18n'
import { eye, eyeClosed } from '@formkit/icons'
// import { createProPlugin, mask } from '@formkit/pro'

// const pro = createProPlugin('fk-ab94ce1e71', { mask })

const isCheckboxAndRadioMultiple = (node) =>
  (node.props.type === 'checkbox' || node.props.type === 'radio')
  && node.props.options

function addAsteriskPlugin (node) {
  node.on('created', () => {
    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      const isRequired = node.props.parsedRules.some(rule => rule.name === 'required')

      if (isRequired) {
        if (isCheckboxAndRadioMultiple(node)) {
          sectionsSchema.legend = {
            children: ['$label', '*'],
          }
        } else {
          sectionsSchema.label = {
            children: ['$label', '*'],
          }
        }
      }
      return schemaFn(sectionsSchema)
    }
  })
}

const config = {
  locales: { cs, en },
  locale: 'cs',
  plugins: [
    // pro,
    addAsteriskPlugin,
    createAutoAnimatePlugin({ duration: 150 })],
  icons: {
    eye,
    eyeClosed,
  },
  messages: {
    cs: {
      ui: {
        incomplete: 'Ve formuláři jsou pole, která vyžadují Vaši pozornost.',
      },
      validation: {
        required ({ name }) {
          return `Pole ${name} je povinné.`
        },
      }
    },
  },

  inputs: {
    image: createInput(InputImage, {
      props: ['multiple', 'min', 'max', 'accept', 'disabled'],
    }),

    map: createInput(InputMap),

    hint: createInput(InputHint, {
      props: ['title', 'text', 'link'],
    }),

    rangeSlider: createInput(InputRange, {
      props: ['min', 'max', 'step'],
    }),

    repeater: createInput(InputRepeater, {
      props: ['schema', 'min', 'max', 'addButtonText'],
    }),

    // dateTimePicker: createInput(InputDateTimePicker, {
    //   props: ['range', 'inline', 'multiCalendars', 'enableTimePicker'],
    // }),
  },
}

export default config
