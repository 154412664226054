<script setup lang="ts">
  import useAuth from '~/composables/useAuth'
  import { usePasswordInput } from '~/composables/usePasswordInput'

  const props = defineProps<{
    redirectTo?: string | 'profile'
  }>()

  const emit = defineEmits(['login', 'close'])

  const config = useRuntimeConfig()

  const data = ref({
    login: '',
    password: '',
  })

  const { login, resetPassword, resetSuccess, pending, errored, error } = useAuth({
    redirectTo: props.redirectTo,
  })

  const { icon, inputType, toggleVisibility } = usePasswordInput()

  const hasLostPassword = ref(false)

  function toggleLostPassword(value: boolean) {
    hasLostPassword.value = value
    errored.value = false
  }

  function submitForm() {
    if (hasLostPassword.value) {
      resetPassword(data.value.login)
    } else {
      login(data.value.login, data.value.password)
    }
  }
</script>

<template>
  <div class="login-form">
    <h2 class="u-fs--400 u-mb--200">
      {{ hasLostPassword ? 'Obnovení hesla' : 'Přihlášení' }}
    </h2>
    <p class="login-form__description">
      {{
        hasLostPassword
          ? 'Na e-mail vám zašleme odkaz pro obnovení hesla.'
          : 'Máte - li již na webu ČKA zřízen svůj účet, můžete se přihlásit.'
      }}
    </p>

    <app-form v-if="!resetSuccess" v-model="data" @submit="submitForm">
      <template v-if="hasLostPassword">
        <form-kit type="text" id="login" name="login" validation="required" label="E-mail" />
      </template>
      <template v-else>
        <form-kit type="email" id="login" name="login" validation="required|email" :validation-messages="{
          email: `Pro přihlášení použijte e-mail, který jste uvedli při Vaší registraci.
              V případě potřeby nás můžete kontaktovat na e‑mailu webmaster@cka.cz.`,
        }" label="E-mail" />
        <form-kit :type="inputType" :suffix-icon="icon" @suffix-icon-click="toggleVisibility" id="password"
          name="password" validation="required" label="Heslo" />
      </template>

      <app-alert v-if="errored" class="u-mb--400" error>
        <span v-if="error.includes('404')">Někde se stala chyba, zkontrolujte prosím e-mailovou
          adresu a zkuste to znovu.</span>
        <span v-else v-html="error"></span>
      </app-alert>

      <app-button class="u-mb--300 u-mt--400" block :loading="pending" type="submit">
        {{ hasLostPassword ? 'Obnovit heslo' : 'Přihlásit se' }}
      </app-button>

      <p v-if="hasLostPassword">
        <app-button @click="toggleLostPassword(false)" text>
          <span class="u-fs--100 u-ff--mono">
            Přihlásit se >
          </span>
        </app-button>
      </p>
      <p v-if="!hasLostPassword" class="u-fs--100 u-ff--mono">
        Neznáte své heslo?
        <app-button @click="toggleLostPassword(true)" text>
          <span class="u-fs--100 u-ff--mono">
            Obnovení hesla >
          </span>
        </app-button>
      </p>
      <p class="u-fs--100 u-ff--mono">
        Nejste ještě registrovaní?
        <app-button @click="emit('close')" to="/register" text>
          <span class="u-fs--100 u-ff--mono">
            Registrace >
          </span>
        </app-button>
      </p>
    </app-form>
    <app-alert v-else-if="hasLostPassword" success>
      Na váš e-mail byl odeslán odkaz pro obnovení hesla.
    </app-alert>
  </div>
</template>

<style scoped>
  .login-form__description {
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-100);
  }
</style>
