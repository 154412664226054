export default function deepReplace (data: object | object[string], key: string|string[], callback: (value: string) => any):  object | object[] {
  if (Array.isArray(data)) {
    for (const item of data) {
      deepReplace(item, key, callback)
    }
  } else {
    if (Array.isArray(key)) {
      for (const k of key) {
        if (data && data.hasOwnProperty(k)) {
          data[k] = callback(data[k])
        }
      }
    } else {
      if (data && data.hasOwnProperty(key)) {
        data[key] = callback(data[key])
      }
    }
    for (const property in data) {
      if (Array.isArray(data[property]) || typeof data[property] === 'object') {
        deepReplace(data[property], key, callback)
      }
    }
  }
  return data
}
