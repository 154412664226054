<script setup lang="ts">
  import { onClickOutside, useMediaQuery, useToggle } from '@vueuse/core'
  import SearchForm from '~/components/SearchForm.vue'
  import { useNuxtApp, onMounted, watch, nextTick, useI18n } from '#imports'
  import type { PloneNavigation } from '~/types/Plone'

  const { experimentalFeatures } = useLocaleConfig()
  const { data: site } = useSiteMeta()
  const { isAuthenticated, user } = useAuth()
  const { locale } = useI18n()
  const userDropdown = ref(null)
  const searchForm = ref(null)
  const [isUserDropdownOpened, toggleUserDropdown] = useToggle()
  const [isMobileMenuOpened, toggleMobileMenu] = useToggle()
  const [isSearchActive, toggleSearch] = useToggle()

  const { $plone } = useNuxtApp()
  const { data: navigation } = await useFetch('/api/plone-proxy', {
    params: {
      path: '/@navigation?expand.navigation.depth=3',
      locale: locale.value,
    }
  })

  onMounted(() => {
    watch(isSearchActive, async (val) => {
      if (val) {
        await nextTick()
        searchForm.value.querySelector('input')?.focus()
      }
    })
  })

  onClickOutside(userDropdown, () => toggleUserDropdown(false))
</script>

<template>
  <nav class="the-navigation">
    <app-container>
      <div class="the-navigation__layout">
        <app-button
          class="the-navigation__mobile-menu-toggle"
          @click="toggleMobileMenu"
          title="Menu"
          circle
          light>
          <Icon name="mdi:menu"/>
        </app-button>

        <nuxt-link
          class="the-navigation__logo"
          to="/">
          <img
            v-if="locale === 'en'"
            width="300"
            height="21"
            src="~/assets/images/logo-en.svg"
            alt="Czech Chamber of Architects"/>
          <img
            v-else
            width="265"
            height="24"
            src="~/assets/images/logo-cs.svg"
            alt="Česká komora architektů"/>
        </nuxt-link>

        <div class="the-navigation__menu">
          <mega-menu :data="navigation"/>
        </div>

        <div class="the-navigation__tools">
          <app-button
            @click="toggleSearch"
            class="the-navigation__search-mobile-toggle the-navigation__button u-ml--auto"
            title="Hledat"
            square
            light>
            <Icon
              v-if="isSearchActive"
              name="mdi:close"/>
            <app-icon
              v-else
              name="search"/>
          </app-button>

          <div
            ref="searchForm"
            :class="['the-navigation__search', { 'the-navigation__search--active': isSearchActive }, 'u-ml--auto']">
            <search-form @submit="toggleSearch(false)"/>
          </div>

          <div
            v-if="locale === 'cs'"
            class="the-navigation__user"
            ref="userDropdown">
            <app-button
              class="the-navigation__user-toggle  the-navigation__button"
              :square="!isUserDropdownOpened"
              :light="!isUserDropdownOpened"
              :circle="isUserDropdownOpened || isAuthenticated"
              aria-label="Váš účet"
              @click.native="toggleUserDropdown">
              <span v-if="isAuthenticated && !isUserDropdownOpened">
                {{ user?.initials }}
              </span>
              <template v-else>
                <Icon
                  v-if="isUserDropdownOpened"
                  name="mdi:close"/>
                <app-icon
                  v-else
                  name="user"/>
              </template>
            </app-button>
            <div
              v-if="isUserDropdownOpened"
              v-motion
              :initial="{ opacity: 0, scale: 0.5 }"
              :leave="{ opacity: 0 }"
              :enter="{ opacity: 1, scale: 1 }"
              class="the-navigation__user-dropdown">
              <login-form
                v-if="!isAuthenticated"
                @close="toggleUserDropdown(false)"
                @login="toggleUserDropdown(false)"/>
              <user-menu
                v-else
                @close="toggleUserDropdown(false)"/>
            </div>
          </div>

          <div class="the-navigation__language-switcher">
            <language-switcher/>
          </div>

          <app-button
            v-if="site?.contact_page"
            :to="site.contact_page?.url"
            class="the-navigation__contact the-navigation__button"
            :title="site.contact_page?.title"
            :aria-label="site.contact_page?.title"
            square
            light>
            <app-icon name="telephone"/>
          </app-button>
        </div>
      </div>
    </app-container>
    <mobile-menu
      v-model="isMobileMenuOpened"
      :data="navigation"/>
  </nav>
</template>

<style scoped>
  .the-navigation {
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    height: var(--nav-height);
    width: 100%;
    padding: var(--gap-100) 0;
    background: var(--c-background-light);
    border-bottom: 1px solid var(--c-text-light);
    z-index: 1010;

    @media (--sm) {
      padding: 0;
    }

    @media print {
      display: none;
    }
  }

  .the-navigation__search {
    height: 100%;
    display: none;
    @media (min-width: 600px) {
      display: block;
    }
    @media (--xs) {
      position: absolute;
      left: 0;
      top: 100%;
      background: var(--c-background-light);
      width: calc(100% - (var(--gap-400) * 2));
      height: auto;
      padding: var(--gap-300) var(--gap-400);
      margin: 0 !important;
      border: 1px solid var(--c-text-light);
    }
    & :deep(.app-form__inner) {
      height: var(--gap-500);
    }
  }

  .the-navigation__search--active {
    display: block;
  }

  .the-navigation__layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (--md) {
      justify-content: flex-start;
    }
  }

  .the-navigation__tools {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    flex-grow: 0;
    gap: var(--gap-200);
  }

  .the-navigation__button {
    aspect-ratio: 1/1;
    box-sizing: border-box;
    @media (--xs) {
      width: var(--gap-500);
      height: var(--gap-500);
    }
  }

  .the-navigation__logo {
    width: clamp(200px, 16vw, 320px);
    display: block;
    padding: var(--gap-400) 0;
    margin-left: var(--gap-400);
    margin-right: var(--gap-400);

    & img {
      width: 100%;
    }

    @media (--xs) {
      margin-left: var(--gap-300);
      margin-right: var(--gap-300);
    }

    @media (--sm) {
      margin-right: var(--gap-500);
      margin-left: var(--gap-500);
    }

    @media (--md) {
      margin-left: 0;
      margin-right: var(--gap-400);
      padding: clamp(18px, 1.25vmax, 22px) 0;
    }
    @media (--lg) {
      margin-right: var(--gap-600);
    }
  }

  .the-navigation__menu {
    display: none;
    @media (--md) {
      display: block;
      margin-right: auto;
    }
  }

  .the-navigation__mobile-menu-toggle {
    display: block;
    & :deep(.app-button__text) {
      padding-top: 0;
    }
    & :deep(svg) {
      width: 1.4em;
      height: 1.4em;
    }
    @media (--md) {
      display: none !important;
    }
  }

  .the-navigation__search-mobile-toggle {
    display: flex;
    @media (--sm) {
      display: none !important;
    }
  }

  .the-navigation__contact {
    margin-right: var(--gap-100);
    display: none;
    @media (--sm) {
      display: flex;
    }
    @media (--md) {
      margin-left: 0;
      margin-right: var(--gap-200);
    }
  }

  .the-navigation__user {
    position: relative;
    display: flex;
  }

  .the-navigation__user-toggle {
    position: relative;
    z-index: 1010;
  }

  .the-navigation__user-dropdown {
    position: absolute;
    right: var(--gap-400);
    top: var(--gap-400);
    background: var(--c-background-light);
    padding: var(--gap-500) var(--gap-400) var(--gap-400);
    border: 1px solid var(--c-primary);
    min-width: 60vw;
    transform-origin: 100% 0;
    z-index: 1000;
    max-height: calc(100vh - var(--gap-400) - var(--gap-700));
    overflow-y: auto;
    @media (--sm) {
      min-width: 50vw;
    }
    @media (--md) {
      min-width: 30vw;
    }
    @media (--lg) {
      min-width: 25vw;
    }
    @media (min-width: 1340px) {
      min-width: 20vw;
    }
  }

  .the-navigation__contact {
    &:deep(.app-icon) {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .the-navigation__user-toggle {
    &:deep(svg) {
      width: 1.7em;
      height: 1.7em;
    }
  }

  .the-navigation__language-switcher {
    @media (--xs) {
      display: none;
    }
  }

  .the-navigation :deep(.app-container) {
    @media (max-width: 1340px) {
      width: 100%;
    }
  }
</style>
