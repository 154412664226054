<script setup lang="ts">
  import Loading from 'vue-loading-overlay'
  import 'vue-loading-overlay/dist/css/index.css'

  const props = withDefaults(defineProps<{
    loading: boolean,
    overlay?: boolean
  }>(), {
    overlay: true,
  })

  const isActive = computed(() => props.loading)
</script>

<template>
  <loading
    :class="['app-loading', { 'app-loading--overlay': overlay }]"
    color="var(--c-primary)"
    :background-color="overlay ? 'var(--c-background)' : 'var(--c-background-light)'"
    :z-index="800"
    :opacity="overlay ? .75 : 0"
    :is-full-page="false"
    v-model:active="isActive"/>
</template>

<style scoped>
  .app-loading :deep(svg) {
    display: block;
  }

  .app-loading--overlay {
    align-items: flex-start;
    padding-top: 25vh;
  }
</style>
