<script setup lang="ts">
  import logo from '~/assets/images/emblem.png'
  import type { PloneSiteMetadata } from '~/types/Plone'

  const { notify } = useBugsnag()
  const route = useRoute()
  const { data: site }: { data: Ref<PloneSiteMetadata> } = useSiteMeta()

  useRealVh()

  const hasAlerts = computed(() => {
    return site?.value?.alerts?.length > 0
  })

  function onError (e) {
    console.error(e)
    notify(e)
  }
</script>

<template>
  <div :class="['app', { 'app--has-alerts': hasAlerts }]">
    <SchemaOrgOrganization
      name="Česká komora architektů"
      in-language="cs-CZ"
      :logo="logo"
      :same-as="['https://www.facebook.com/CeskaKomoraArchitektu']"
      :address="{
          streetAddress: 'Josefská 34/6',
          addressLocality: 'Praha 1',
          postalCode: '11800',
          addressCountry: 'CZ',
        }"
    />
    <SchemaOrgWebSite
      in-language="cs-CZ"
      name="Česká komora architektů"/>
    <a
      href="#obsah"
      class="u-hide-visually u-hide-visually--focusable">
      Přeskočit na obsah
    </a>
    <template v-if="hasAlerts">
      <app-alert
        v-for="alert in site.alerts"
        full-width
        dismissable
        compact
        :type="alert.type === 'info' ? 'dark' : alert.type">
        {{ alert.text }}
        <nuxt-link
          v-if="alert.more_link && alert.more_link_text"
          :to="alert.more_link">
          {{ alert.more_link_text }} >
        </nuxt-link>
      </app-alert>
    </template>
    <the-navigation class="app__navigation"/>
    <div
      class="app__main"
      id="obsah">
      <NuxtErrorBoundary @error="onError">
        <template #error="{ error }">
          <app-alert
            error
            full-width
            dismissable>
            Vyskytla se chyba, zkuste prosím <a
            :href="route.path"
            class="u-c--white">obnovit stránku</a>.
          </app-alert>
        </template>
        <nuxt-page/>
      </NuxtErrorBoundary>
    </div>
    <client-only>
      <cookie-consent
        :cookie-preferences="[
            {
              title: 'Analytické nástroje',
              description: 'Pro analýzu návštěvnosti a chování uživatelů ukládáme cookies těchto analytických nástrojů:',
              items: [
                { label: 'Google Analytics', name: 'gtag', value: true },
              ],
            },
          ]"/>
    </client-only>
    <the-footer class="app__footer"/>
    <the-loading-indicator/>
  </div>
</template>

<style scoped>
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .app__alert {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1001;
  }

  .app__main {
    padding: var(--nav-height) 0;
    margin-bottom: auto;
    @media print {
      padding: 0;
    }
  }

  .app--has-alerts .app__navigation {
    position: sticky;
    left: 0;
    top: 0;
  }


  .app--has-alerts .app__main {
    padding-top: 0;
  }
</style>
