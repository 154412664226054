import { Ref } from 'vue-demi'
import { useMutationObserver, useResizeObserver, useThrottleFn, useDebounceFn } from '@vueuse/core'

export const useMatchHeights = (target: Ref<HTMLElement>, selectors: string | string[]): void => {
  const gridColumns = ref()

  onMounted(() => {
    gridColumns.value = getColumns()
    if (selectors) {
      matchHeights()
    }
  })

  useMutationObserver(target, useDebounceFn(() => {
    if (selectors) {
      matchHeights()
    }
  }, 100), {
    childList: true,
    attributes: false,
    characterData: false,
  })

  useResizeObserver(target, useThrottleFn(() => {
    gridColumns.value = getColumns()
    if (selectors) {
      matchHeights()
    }
  }, 240))

  function matchHeights () {
    const selectorsArray = !Array.isArray(selectors)
      ? [selectors]
      : selectors

    const cells = target.value.children

    if (cells) {
      selectorsArray.forEach(selector => {
        const items = [...cells].map((cell) => {
          const element: HTMLElement = cell.querySelector(selector)

          if (!element) {
            return {
              height: 0,
              element: null,
            }
          }
          element.style.height = 'auto'
          const { height } = element.getBoundingClientRect()

          return {
            height,
            element,
          }
        })

        const rows =
          gridColumns.value > 0 ?
            useChunk(items, gridColumns.value)
            : [items]

        rows.forEach((row) => {
          const maxHeight = useMax(row.map(({ height }) => height))
          row.forEach(({ element }) => {
            if (gridColumns.value > 1) {
              element.style.height = `${maxHeight}px`
            } else {
              element.style.height = ''
            }
          })
        })
      })
    }
  }

  function getColumns () {
    const computedStyles = window.getComputedStyle(target.value)
    const gridTemplateColumns = computedStyles.getPropertyValue('grid-template-columns')

    if (!gridTemplateColumns) {
      return -1
    }

    return gridTemplateColumns.split(' ').length
  }
}

