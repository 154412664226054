<script setup lang="ts">
  import { useDebounceFn } from '@vueuse/core'
  import Slider from '@vueform/slider'
  import type { FormKitContext } from '@formkit/core'

  const props = defineProps<{ context: FormKitContext }>()

  const minValue = ref(props.context?._value?.[0])
  const maxValue = ref(props.context?._value?.[1])

  const handleInput = useDebounceFn(() => {
    if (minValue.value == props.context.min && maxValue.value == props.context.max) {
      props.context.node.input([props.context.min, props.context.max])
    } else {
      props.context.node.input([minValue.value, maxValue.value])
    }
  }, 100)

  const handleSliderInput = useDebounceFn((val) => {
    if (val[0] == props.context.min && val[1] == props.context.max) {
      props.context.node.input([props.context.min, props.context.max])
    } else {
      props.context.node.input(val)
    }
    minValue.value = val[0]
    maxValue.value = val[1]
  }, 100)
</script>

<template>
  <div class="input-range">
    <Slider
      @update="handleSliderInput"
      class="input-range__slider"
      v-model="context._value"
      :options="{ start: context._value }"
      :step="context.step"
      :min="context.min"
      :max="context.max"
      :tooltips="false"/>

    <div class="input-range__inputs">
      <input
        class="input-range__input"
        @input="handleInput"
        ref="minElm"
        v-model.number="minValue"
        :step="context.step"
        :min="context.min"
        :max="context._value?.[1]"
        :name="`${context.id}_from`"
        type="number"/>
      <input
        class="input-range__input"
        @input="handleInput"
        ref="maxElm"
        v-model.number="maxValue"
        :step="context.step"
        :min="context._value?.[0]"
        :name="`${context.id}_to`"
        :max="context.max"
        type="number"/>
    </div>
  </div>
</template>

<style scoped>
  .input-range__slider {
    --slider-height: 12px;
    --slider-radius: 0;

    --slider-bg: var(--c-gray-300);
    --slider-connect-bg: var(--c-primary);
    --slider-connect-bg-disabled: var(--c-gray-500);

    --slider-handle-width: 21px;
    --slider-handle-height: 21px;
    --slider-handle-bg: var(--c-primary);
    --slider-handle-ring-color: var(--c-primary);


    --slider-handle-shadow: none;
    --slider-handle-shadow-active: 0.5px 0.5px 2px 1px rgba(0,0,0,.42);
  }

  .input-range__inputs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: var(--gap-400);
    padding-top: var(--gap-400);
  }

  .input-range__input {
    border: 1px solid var(--c-gray-400);
    border-radius: 0;
    font-family: var(--font-family);
    font-size: var(--font-size-400);
    padding: var(--gap-300) var(--gap-300);
    transition: var(--s-normal);
    box-sizing: border-box;
    display: block;
    width: 100%;
    @media (--sm) {
      padding: var(--gap-250) var(--gap-300);
    }
    @media (--lg) {
      padding: var(--gap-225) var(--gap-300);
    }

    &:focus {
      border-color: var(--c-primary);
      outline: 0;
    }
  }
</style>

<style src="@vueform/slider/themes/default.css"></style>
