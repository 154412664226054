export default function (name: string): string {
  const nameFragments = name?.trim().split(' ').map(x => x[0])
  
  if (!nameFragments) {
    return
  }
    
  const initials = `${nameFragments[0]}${nameFragments.length > 1 
    ? nameFragments[nameFragments.length - 1] 
      ? nameFragments[nameFragments.length - 1] 
      : '' 
    : ''}`

  return initials
}
