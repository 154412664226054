<script setup lang="ts">
  import { onClickOutside } from '@vueuse/core'
  import type { PloneNavigation, PloneNavigationItem } from '~/types/Plone'
  import type { Ref } from 'vue'

  const props = defineProps<{ modelValue: boolean, data: PloneNavigation }>()
  const emit = defineEmits(['update:modelValue', 'close'])
  const { experimentalFeatures } = useLocaleConfig()
  const panel = ref(null)

  const isOpened = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', value)
  })

  const toggleItem = (item: PloneNavigationItem) => {
    if (activeItems.value.includes(item['@id'])) {
      activeItems.value = activeItems.value.filter(i => i !== item['@id'])
    } else {
      activeItems.value.push(item['@id'])
    }
  }

  const isItemActive = (item: PloneNavigationItem) => {
    return activeItems.value.includes(item['@id'])
  }

  const activeItems: Ref<string[]> = ref([])

  const navigation = toRef(props, 'data')

  onClickOutside(panel, () => isOpened.value = false)
</script>

<template>
  <div class="mobile-menu">
    <div
      v-if="isOpened"
      v-motion-fade
      class="mobile-menu__overlay"></div>
    <div
      v-if="isOpened"
      ref="panel"
      v-motion-slide-left
      class="mobile-menu__panel">
      <div class="mobile-menu__controls">
        <app-button
          class="the-navigation__mobile-menu-toggle"
          title="Menu"
          @click="isOpened = false"
          text
          light>
          <Icon name="mdi:close" size="24"/>
        </app-button>
        <div
          v-if="experimentalFeatures"
          class="mobile-menu__locale-switch">
          <lazy-language-switcher/>
        </div>
      </div>
      <ul class="mobile-menu__items mobile-menu__items--l-1 u-list-unstyled">
        <li
          v-for="item in navigation.items"
          class="mobile-menu__item mobile-menu__item--l-1 u-mb--500"
          :key="item.UUID">
          <nuxt-link
            class="u-fs--700 u-mb--300"
            @click.native="isOpened = false"
            :to="item['@id']">
            {{ item.title }}
          </nuxt-link>

          <ul class="mobile-menu__items mobile-menu__items--l-2 u-list-unstyled">
            <li
              v-for="subItem in item.items"
              class="mobile-menu__item mobile-menu__item--l-2"
              :key="subItem.UUID">
              <div class="mobile-menu__item-layout">
                <nuxt-link
                  class="u-fs--500"
                  @click.native="isOpened = false"
                  :to="subItem['@id']">
                  {{ subItem.title }}
                </nuxt-link>
                <app-button
                  @click="toggleItem(subItem)"
                  square
                  text
                  class="mobile-menu__sub-toggle">
                  <Icon
                    v-if="subItem.items && subItem.items.length > 0"
                    size="28"
                    :name="activeItems.includes(subItem['@id']) ? 'mdi:chevron-up' : 'mdi:chevron-down'"/>
                </app-button>
              </div>
              <ul
                v-if="activeItems.includes(subItem['@id'])"
                class="mobile-menu__items mobile-menu__items--l-3 u-list-unstyled">
                <li
                  v-for="subSubItem in subItem.items"
                  class="mobile-menu__item mobile-menu__item--l-3"
                  :key="subSubItem.UUID">
                  <nuxt-link
                    class="u-fs--400"
                    @click.native="isOpened = false"
                    :to="subSubItem['@id']">
                    {{ subSubItem.title }}
                  </nuxt-link>
                </li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
  .mobile-menu__panel {
    height: var(--vh-100);
    position: fixed;
    max-width: 325px;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.7);
    border-right: solid 1px var(--c-text-light);
    backdrop-filter: blur(4px);
    z-index: 1030;
    overscroll-behavior: contain;
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: var(--c-gray-200);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--c-text-light);
    }
  }

  .mobile-menu__controls {
    position: sticky;
    top: 0;
    left: 0;
    padding: var(--gap-200) var(--gap-200) var(--gap-200) var(--gap-400);
    border-bottom: solid 1px var(--c-text-light);
    background-color: rgba(255, 255, 255, 0.8);
    backdrop-filter: blur(10px);
    z-index: 100;
    display: flex;
    justify-content: space-between;
  }

  .mobile-menu__items {
    width: 100%;
    box-sizing: border-box;
  }

  .mobile-menu__items--l-1 {
    padding: var(--gap-500)  0;
  }

  .mobile-menu__item {
    position: relative;
    width: 100%;
    line-height: 1;
  }

  .mobile-menu__items--l-3 {
    border-top: 1px solid var(--c-gray-200);
    padding: var(--gap-300) 0;
    overflow: hidden;
  }

  .mobile-menu__item a {
    color: var(--c-text);
    text-decoration: none;
    display: block;
    padding: var(--gap-250) var(--gap-400);
    &.router-link-exact-active,
    &:focus,
    &:hover {
      color: var(--c-primary);
    }
  }

  .mobile-menu__item--l-3 {
    & > a {
      padding: var(--gap-250) calc(var(--gap-400) + var(--gap-300));
    }
  }

  .mobile-menu__item--l-2 > a {
    border-bottom: 1px;
  }

  .mobile-menu__item--l-2 {
    border-top: 1px solid var(--c-gray-200);
    &:last-of-type {
      border-bottom: 1px solid var(--c-gray-200);
    }
  }

  .mobile-menu__overlay {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1020;
  }

  .mobile-menu__item-layout {
    display: flex;
    flex-direction: row;
    & > a {
      display: flex;
      align-items: center;
      flex-grow: 1;
    }
  }

  .mobile-menu__sub-toggle  {
    flex-grow: 0;
    border-left: 1px solid var(--c-gray-200) !important;
  }

  .mobile-menu__locale-switch {
  }
</style>
