import sanitizeHtml from 'sanitize-html'

export const sanitize = (text: string) => sanitizeHtml(text, {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img'])
})

export const formatText = (content: string) => {
  if (!content || typeof content !== 'string') {
    return ''
  }

  const rules = [
    { search: /(\s|^)([ai])(?:\s+)(\S)/g, replace: '$1$2\u00A0$3' },
    { search: /(\s|^)([KkSsVvZzOoUuAI])(?:\s+)(\S)/g, replace: '$1$2\u00A0$3' },
    { search: /(\u00B6|\u00A7|\u00A9|\u2122|\u00AE) /g, replace: '$1\u00A0' },
    { search: / {2,}/g, replace: ' ' },
  ]

  let result = content

  rules.forEach(({ search, replace }) => {
    result = result.replace(search, replace)
  })

  return result
}
