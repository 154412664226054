<script setup lang="ts">
  import { useNuxtApp } from '#app'
  import { useSlots } from 'vue'
  import hasSlotContent from '~/utils/has-slot-content'

  const props = withDefaults(defineProps<{
    href?: string
    target?: string
    large?: boolean
    elevated?: boolean
    outlined?: boolean
    headerLayout?: string
    layout?: 'row' | 'column' | 'halves'
    innerClass?: string
    tag?: string
  }>(), { layout: 'column' })

  const app = useNuxtApp()
  const slots = useSlots()

  const hasMedia = hasSlotContent(slots.media)
  const hasHeader = hasSlotContent(slots.header)
  const hasMeta = hasSlotContent(slots.meta)
  const hasContent = hasSlotContent(slots.default)
  const hasFooter = hasSlotContent(slots.footer)

  const component = computed(() => {
    return props.href
      ? resolveComponent('NuxtLink')
      : props.tag
        ? props.tag
        : 'div'
  })

</script>

<template>
  <component
    :is="component"
    :to="href"
    :target="target"
    :rel="target ? 'noreferer' : null"
    :class="[
      'app-card',
      `app-card--${layout}`,
      {
        'app-card--outlined': outlined,
        'app-card--elevated': elevated
      }]">
    <div
      v-if="hasMedia"
      class="app-card__media">
      <slot name="media"/>
    </div>

    <div :class="['app-card__wrap', innerClass]">
      <header
        v-if="hasHeader"
        :class="['app-card__header', { 'is-large': large }]">
        <slot name="header"/>
      </header>

      <div
        v-if="hasMeta"
        class="app-card__meta">
        <slot name="meta"/>
      </div>

      <div
        v-if="hasContent"
        class="app-card__content">
        <slot/>
      </div>

      <footer
        v-if="hasFooter"
        class="app-card__footer">
        <slot name="footer"/>
      </footer>
    </div>
  </component>
</template>

<style scoped>
  .app-card {
    display: flex;
    flex-direction: column;
    background: var(--c-background-light);
  }

  .app-card__wrap {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: var(--gap-400);
    box-sizing: border-box;
  }

  .app-card--row {
    & .app-card__wrap {
      display: grid;
      height: auto;
      grid-column-gap: var(--gap-400);
      padding: var(--gap-450);
      grid-template-columns: repeat(2, 1fr);
      grid-template-areas:
          "header footer"
          "content footer";

      @media (--xs) {
        grid-template-areas:
          "header header"
          "content content"
          "footer none";
      }

      & .app-card__header {
        grid-area: header;
        display: flex;
        align-items: center;
      }

      & .app-card__content {
        grid-area: content;
        margin-top: var(--gap-300);
      }

      & .app-card__footer {
        grid-area: footer;
      }

      @media (--sm) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-areas:
          "header header footer"
          "content content content";
      }

      @media (--lg) {
        grid-template-columns: repeat(4, 1fr);
        grid-template-areas:
          "header header header footer"
          "content content content empty";
      }
    }

    & .app-card__meta {
      @media (--sm) {
        text-align: right;
      }
    }
  }

  .app-card--halves {
    @media (--sm) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-areas: "content media";
      & .app-card__wrap {
        grid-area: content;
      }
      & .app-card__media {
        grid-area: media;
      }
    }
  }

  .app-card__media {
    &:deep(img) {
      display: block;
      width: 100%;
      height: auto;
      transition: var(--s-normal);
    }

    &:deep(.aspect-ratio),
    &:deep(a) {
      display: block;
      overflow: hidden;
    }

    &:deep(a:hover img) {
      transform: scale(1.05) !important;
    }
  }

  .app-card__meta {
    font-size: var(--font-size-300);

    & :deep(a) {
      color: var(--c-text);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .app-card__content {
    width: 100%;
  }

  a.app-card {
    text-decoration: none;
    color: var(--c-text);

    & .app-card__header:not(.is-large) {
      color: var(--c-primary);
      text-decoration: underline;
    }

    &:hover .app-card__header {
      text-decoration: none;
    }

    &:hover .app-card__media :deep(img) {
      transform: scale(1.05) !important;
    }

    & .app-card__meta {
      color: var(--c-text);
      text-decoration: none !important;
    }
  }

  .app-card--outlined {
    border: solid 1px var(--c-gray-400);
  }

  .app-card--elevated {
    box-shadow: var(--box-shadow);
  }

  .app-card :deep(table) {
    & th,
    & td {
      padding-left: 0;
      &:not(:last-child) {
        padding-right: var(--gap-400);
      }
    }
  }
</style>
