import { isNumber } from 'lodash-es'

export function formatCurrency (amount: string | number): string {
  const theAmount = amount !== 0 && !amount
      ? 0
      : isNumber(amount)
        ? amount
        : parseFloat(amount)

  return theAmount.toLocaleString('cs-CZ', {
    style: 'currency',
    currency: 'CZK',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
}

export function deFormatCurrency (amount: string): number {
  if (!amount) {
    return
  }

  return Number(amount.replace(/[^0-9\.-]+/g, ""))
}
