<script setup lang="ts">
  const props = defineProps<{
    path: string
  }>()

  const { data, pending, error } = usePloneData(props.path)
</script>

<template>
  <div class="async-page">
    <app-loading :loading="pending" :overlay="false"/>
    <h1>{{ data?.title }}</h1>
    <div v-html="data?.text?.data"/>
  </div>
</template>

<style scoped>
  .async-page {
    min-height: 80px;
  }
</style>
