<script setup>
  const props = defineProps({
    error: Object
  })

  const errorCode = isNumber(props.error?.statusCode)
    ? props.error.statusCode
    : Number(props.error.statusCode)
</script>

<template>
  <div class="app">
    <the-navigation class="app__navigation" />
    <div class="app__main">
      <layout-error
        :error-code="errorCode"/>
    </div>
    <the-footer class="app__footer" />
    <client-only>
      <cookie-consent
        :cookie-preferences="[
          {
            title: 'Analytické nástroje',
            description: 'Pro analýzu návštěvnosti a chování uživatelů ukládáme cookies těchto analytických nástrojů:',
            items: [
              { label: 'Google Analytics', name: 'gtag', value: true },
            ],
          },
        ]"/>
    </client-only>
    <the-loading-indicator />
  </div>
</template>

<style scoped>
  .app {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }

  .app__alert {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    z-index: 1001;
  }

  .app__main {
    padding: calc(var(--gap-600) + var(--gap-400)) 0;
    margin-bottom: auto;
    @media print {
      padding: 0;
    }
  }
</style>
