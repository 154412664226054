<script setup lang="ts">
  import type { FormKitSchemaNode } from '@formkit/core'

  const props = withDefaults(defineProps<{
    buttonType?: 'icon' | 'text'
  }>(), {
    buttonType: 'icon',
  })

  const emit = defineEmits(['submit'])
  const localePath = useLocalePath()

  const router = useRouter()
  const route = useRoute()

  const data = ref({
    text: route.query?.text,
  })

  const schema: FormKitSchemaNode[] = [
    {
      $formkit: 'text',
      id: 'searchTerm',
      name: 'text',
      'aria-label': 'Zadejte hledaný výraz',
    },
  ]

  function submit () {
    if (!data.value.text) {
      return
    }
    emit('submit', data.value.text)

    router.push(localePath({
      name: 'search',
      query: {
        ...route.query,
        text: data.value.text,
      }
    }))
  }
</script>

<template>
  <app-form
    class="search-form"
    v-model="data"
    :schema="schema"
    @submit="submit">
    <app-button
      v-if="buttonType === 'icon'"
      class="search-form__submit"
      square
      aria-label="Hledat"
      title="Hledat"
      type="submit">
      <app-icon name="search"/>
    </app-button>
    <app-button
      v-if="buttonType === 'text'"
      class="search-form__submit"
      primary
      type="submit">
      Hledat
    </app-button>
  </app-form>
</template>

<style scoped>
  .search-form {
    position: relative;

    & :deep(.app-form__wrapper) {
      margin-bottom: 0 !important;
    }

    &:hover {
      & .search-form__submit {
        border-color: var(--c-gray-450);
      }
    }

    &:focus-within {
      & .search-form__submit {
        border-color: var(--c-primary);
      }
    }
  }

  .search-form__submit {
    position: absolute !important;
    right: 0;
    top: 0;
    border: solid 1px var(--c-text-light);
    height: 100%;
    aspect-ratio: 1/1;
    transition: var(--s-normal);
  }

  :deep(.app-form__input) {
    padding-right: var(--gap-500) !important;
  }

  :deep(.app-form__inner) {
    max-width: 100% !important;
  }
</style>
