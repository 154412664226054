<script setup lang="ts">
  const props = defineProps<{
    multiple?: boolean,
    disabled?: boolean,
    accept?: string,
    label?: string,
    type?: 'file' | 'image' | 'video' | 'audio',
    forceLandscape?: boolean,
    loading?: boolean,
  }>()

  const isDragging = ref(false)
  const isWorking = ref(false)
  const error = ref(null)
  const text = computed(() => {
    if (error.value) {
      return error.value
    }

    return props.label || `Přetažením nebo kliknutím přidejte ${props.multiple ? 'soubory' : 'soubor'}`
  })
</script>

<template>
  <label class="drop-zone">
    <input
      class="drop-zone__input"
      type="file"
      :accept="accept"
      :disabled="disabled"
      :multiple="multiple"
      @dragenter="isDragging = true"
      @dragleave="isDragging = false"
      @drop="isDragging = false"/>
    <span
      :class="['drop-zone__frame', {
      'drop-zone__frame--highlight': isDragging,
      'drop-zone__frame--has-error': error,
      'drop-zone__frame--disabled': disabled,
    }]">
      {{ text }}
      <mdicon
        class="drop-zone__icon"
        name="cloud-upload-outline"/>
    </span>

    <app-progress
      v-if="loading"
      class="drop-zone__progress"
      color="primary"/>
  </label>
</template>

<style scoped>
  .drop-zone {
    display: flex;
    position: relative;
  }

  .drop-zone__input {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    z-index: 10;
    width: 100%;
    cursor: pointer;
  }

  .drop-zone__frame {
    content: '';
    width: 100%;
    position: relative;
    border: 1px solid var(--c-gray-400);
    cursor: pointer;
    display: flex;
    background: var(--c-background-light);
    padding: var(--gap-300) var(--gap-600) var(--gap-300) var(--gap-300);
    color: var(--c-text);
    line-height: 1;
  }

  .drop-zone__frame--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .drop-zone__frame--highlight {
    background: var(--c-primary);
    color: #fff;
  }

  .drop-zone__frame--has-error {
    color: var(--c-error);
    border: 2px dashed var(--c-error);
  }

  .drop-zone__icon {
    position: absolute;
    right: var(--gap-400);
    top: 50%;
    transform: translate(0, -50%);
    opacity: 0.6;
  }
</style>
