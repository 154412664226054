import { useStyleTag, useThrottleFn } from '@vueuse/core'

export default function (cssVarName = 'vh') {
  const { css } = useStyleTag('')

  const throttledFn = useThrottleFn(() => {
    css.value = getCss(cssVarName)
  }, 100)

  onMounted(() => {
    css.value = getCss(cssVarName)
    window.addEventListener('resize', throttledFn)
  })
}

function getCss (cssVarName) {
  const vh = window.innerHeight * 0.01
  return `:root {
    --${cssVarName}: ${vh}px;
    --${cssVarName}-50: calc(var(--${cssVarName}, 1vh) * 50);
    --${cssVarName}-100: calc(var(--${cssVarName}, 1vh) * 100);
  }`
}