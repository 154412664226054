<script setup lang="ts">
  import useAuth from '~/composables/useAuth'
  import { useNuxtApp } from '#app'
  const app = useNuxtApp()

  const emit = defineEmits(['close'])

  const config = useRuntimeConfig()

  const { logout, user } = useAuth()

  const { data: userData, pending } = useUserProfile(user)

  function doLogout () {
    logout()
    emit('close')
  }
</script>

<template>
  <div class="user-menu">
    <h2 class="user-menu__title">přihlášený uživatel</h2>
    <p>{{ user?.fullname }}</p>
    <ul class="u-list-unstyled" v-if="!pending">
      <template v-if="userData.personal_links">
        <li
          v-for="item in userData.personal_links"
          class="user-menu__item">
          <app-button
            text
            @click="emit('close')"
            :_target="item.target"
            :to="useRelativeLink(item.url)">
            {{ item.label }} >
          </app-button>
        </li>
      </template>

      <li class="user-menu__item">
        <app-button
          text
          @click.native="doLogout">
          Odhlásit se >
        </app-button>
      </li>
    </ul>
  </div>
</template>

<style scoped>
  .user-menu__title {
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-100);
    margin-bottom: var(--gap-200);
  }

  .user-menu__item:not(:last-child) {
    margin-bottom: var(--gap-300);
  }
</style>
