<script setup lang="ts">
  import { vOnClickOutside } from '@vueuse/components'
  import type { FormKitContext } from '@formkit/core'
  import { useToggle } from '@vueuse/core'

  const props = defineProps<{ context: FormKitContext }>()

  const isActive = ref(false)

  const isPdf = computed(() => props.context.link?.endsWith('.pdf'))

  function closeModal () {
    if (isActive.value) {
      isActive.value = false
    }
  }
</script>

<template>
  <component
    :is="isPdf ? 'a' : 'span'"
    v-if="context.link || context.text"
    :href="isPdf ? context.link : undefined"
    :target="isPdf ? '_blank' : undefined"
    class="input-hint">
    <mdicon
      class="input-hint__icon"
      name="information-outline"
      focusable
      @click="isActive = !isActive"/>
    <app-modal v-if="!isPdf" v-model="isActive">
      <app-card
        :class="['input-hint__window', { 'input-hint__window--wide': context.link }]"
        v-on-click-outside="closeModal">
        <app-button
          class="input-hint__close"
          circle
          @click="closeModal">
          <mdicon name="close"/>
        </app-button>
        <h3 v-if="context.title">{{ context.title }}</h3>
        <p
          v-if="context.text"
          v-html="context.text"
          class="u-fs--300"/>
        <async-page
          v-if="props.context.link"
          :path="props.context.link"/>
      </app-card>
    </app-modal>
  </component>
</template>

<style scoped>
  .input-hint__icon {
    cursor: pointer;
    color: var(--c-primary);
    transition: var(--s-fast);
    &:hover,
    &:focus {
      color: var(--c-text);
    }
  }

  .input-hint__window {
    max-width: 480px;
    margin: 0 auto;
    position: relative;
  }

  .input-hint__window--wide {
    max-width: none;
    width: 100%;
    @media (--md) {
      width: 60vw;
    }
  }

  .input-hint__close {
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%,-50%);
  }
</style>
