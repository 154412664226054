<script setup lang="ts">
  import { useNProgress } from '@vueuse/integrations/useNProgress'

  import type { Ref } from 'vue'
  import { useNuxtApp } from '#app'
  const app = useNuxtApp()
  const { start, done, progress, isLoading } = useNProgress()

  app.hook('page:start', () => {
    start()
  })

  app.hook('page:finish', () => {
    done()
  })
</script>

<template>
  <div class="the-loading-indicator"></div>
</template>

<style>
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: var(--c-primary);

    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;

    width: 100%;
    height: 2px;
  }

  /* Fancy blur effect */
  #nprogress .peg {
    display: block;
    position: absolute;
    right: 0px;
    width: 100px;
    height: 100%;
    box-shadow: 0 0 10px var(--c-primary), 0 0 5px var(--c-primary);
    opacity: 1.0;
    transform: rotate(3deg) translate(0px, -4px);
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: var(--c-primary);
    border-left-color: var(--c-primary);
    border-radius: 50%;
            animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @-webkit-keyframes nprogress-spinner {
    0%   { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  @keyframes nprogress-spinner {
    0%   { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
</style>
