<script setup lang="ts">
  import { ref } from '#imports'

  const props = withDefaults(defineProps<{
      columns?: number | string
      align?: 'end' | 'start' | 'normal'
      gap?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
      matchHeight?: string | string[] | boolean
    }>(),
    {
      columns: 2,
      gap: '400',
      align: 'normal',
      matchHeight: [],
    })

  const gridElm = ref(null)

  if (props.matchHeight) {
    useMatchHeights(gridElm, props.matchHeight)
  }
</script>

<template>
  <div
    ref="gridElm"
    :class="['app-grid', `app-grid--${columns}-columns`, `app-grid--align-${align}`]"
    :style="{
      gap: `var(--gap-${gap})`,
      alignItems: align
    }">
    <slot/>
  </div>
</template>

<style scoped>
  .app-grid {
    display: grid;
  }

  .app-grid--align-normal :deep(.app-card--column .app-card__meta) {
    margin-top: auto;
  }

  .app-grid--1-columns {
    grid-template-columns: 1fr;
  }

  .app-grid--2-columns {
    @media (--md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .app-grid--3-columns {
    @media (--sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media (--md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  .app-grid--4-columns {
    @media (--sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media (--lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  .app-grid--5-columns {
    @media (--md) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .app-grid--6-columns {
    @media (--sm) {
      grid-template-columns: 1fr 1fr;
    }
    @media (--md) {
      grid-template-columns: 1fr 1fr 1fr;
    }
    @media (--lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .app-grid--7-columns {
    @media (--sm) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }

  .app-grid--8-columns {
    @media (--sm) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    }
  }
</style>
