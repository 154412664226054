<script setup lang="ts">
const { data: site } = useSiteMeta()
</script>

<template>
  <footer class="the-footer">
    <app-container v-if="site">
      <div class="the-footer__layout">
        <div class="the-footer__column">
          <h3>{{ site.newsletter_title }}</h3>
          <client-only>
            <lazy-newsletter-form :conditions-label="site.newsletter_conditions_label"
              :conditions-url="site.newsletter_conditions_url" :placeholder="site.newsletter_placeholder"
              :button-label="site.newsletter_button_label" />
          </client-only>
        </div>

        <div v-if="site.sitemap_links?.length" class="the-footer__column">
          <h3>{{ $t("footer.siteMap") }}</h3>
          <ul class="the-footer__menu-items u-list-unstyled">
            <li v-for="item in site.sitemap_links">
              <nuxt-link class="the-navigation__menu-button" :target="item.target" :to="item.url">
                {{ item.label }}
              </nuxt-link>
            </li>
          </ul>
        </div>

        <div class="the-footer__column">
          <h3>{{ site.contact_title }}</h3>
          <address class="the-footer__text">
            {{ site.contact_name }}<br />
            {{ site.contact_street }}<br />
            {{ site.contact_postal_code }} {{ site.contact_town }}
          </address>
          <p class="the-footer__text">
            <a class="the-footer__contact-link" :href="`tel:${site.contact_phone}`">
              {{ site.contact_phone }} </a><br />
            <a class="the-footer__contact-link" :href="`mailto:${site.contact_email}`">
              {{ site.contact_email }}
            </a>
          </p>
          <p>
            <nuxt-link v-if="site?.contact_page" :to="site.contact_page?.url" class="the-navigation__menu-button">
              kontakty
            </nuxt-link>
          </p>
        </div>

        <div class="the-footer__column">
          <h3>{{ site.pr_title }}</h3>

          <p class="the-footer__text">
            {{ site.pr_name }}<br />
            <a class="the-footer__contact-link" :href="`tel:${site.pr_phone}`">
              {{ site.pr_phone }} </a><br />
            <a class="the-footer__contact-link" :href="`mailto:${site.pr_email}`">
              {{ site.pr_email }}
            </a>
          </p>

          <ul class="the-footer__menu-items u-list-unstyled">
            <li>
              <nuxt-link v-if="site.pr_link_url" class="the-navigation__menu-button" :to="site.pr_link_url">
                {{ site.pr_link_label || "Pro média" }}
              </nuxt-link>
            </li>
            <li v-if="site.facebook_url">
              <a class="the-navigation__menu-button" target="_blank" rel="noopener" :href="site.facebook_url">
                Facebook ČKA >
              </a>
            </li>
            <li v-if="site.twitter_url">
              <a class="the-navigation__menu-button" target="_blank" rel="noopener" :href="site.twitter_url">
                Twitter ČKA >
              </a>
            </li>
            <li v-if="site.instagram_url">
              <a class="the-navigation__menu-button" target="_blank" rel="noopener" :href="site.instagram_url">
                Instagram ČKA >
              </a>
            </li>
            <li v-if="site.linkedin_url">
              <a class="the-navigation__menu-button" target="_blank" rel="noopener" :href="site.linkedin_url">
                LinkedIn ČKA >
              </a>
            </li>
            <li v-if="site.youtube_url">
              <a class="the-navigation__menu-button" target="_blank" rel="noopener" :href="site.youtube_url">
                Youtube ČKA >
              </a>
            </li>
          </ul>
        </div>
      </div>
    </app-container>

    <div class="the-footer__copy">
      <div class="u-mr--auto">
        &copy; {{ new Date().getFullYear() }}
        <nuxt-link :to="localePath('/')">
          {{ $t("orgName") }}
        </nuxt-link>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.the-footer {
  background: var(--c-background-dark);

  & h3 {
    margin-bottom: var(--gap-400);
  }

  @media print {
    display: none;
  }
}

.the-footer__layout {
  padding: var(--gap-400) 0 var(--gap-600) 0;
  display: grid;
  grid-gap: var(--gap-500);

  @media (--sm) {
    grid-template-columns: 1fr 1fr;
  }

  @media (--md) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.the-footer__copy {
  display: flex;
  justify-content: space-between;
  background: var(--c-gray-800);
  padding: var(--gap-200) var(--gap-600);
  line-height: 1;
  font-size: var(--font-size-200);

  &,
  & a {
    color: var(--c-white);
  }
}

.the-footer__contact-link {
  text-decoration: none;
  color: var(--c-text);

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.the-footer__column {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.the-footer__menu-items {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.the-footer__text {
  font-size: var(--font-size-300);
}
</style>
