<script setup lang="ts">
  import type { PloneDocument } from '~/types/Plone'

  const props = withDefaults(defineProps<{
    data?: PloneDocument,
    errorCode?: number
  }>(), { errorCode: 401 })

  const { isAuthenticated, user } = useAuth()

  const theErrorCode = Number(props.data?.error_code || props.errorCode)

  const content = {
    '404': {
      title: 'Tato stránka neexistuje',
      description: 'Je nám líto, ale stránka kterou se chystáte navštívit není na uvedené adrese dostupná. Stránku můžete zkusit vyhledat v&nbsp;našem vyhledávači.',
    },
    '401': {
      title: 'Na zobrazení této stránky nemáte oprávnění',
      description: isAuthenticated.value ? 'Zkuste se prosím přihlásit.' : '',
    },
    '403': {
      title: 'Na zobrazení této stránky nemáte oprávnění',
    },
    '500': {
      title: 'Stránka je dočasně nedostupná',
      description: 'Zkuste to prosím později.',
    },
    '503': {
      title: 'Stránka je dočasně nedostupná',
      description: 'Zkuste to prosím později.',
    },
  }

  const handleError = () => clearError({ redirect: '/' })
</script>

<template>
  <app-page class="layout-error" :data="{ title: content[theErrorCode].title }">
    <template #header>
      <div class="layout-error__header">
        <h1>{{ content[theErrorCode].title }}</h1>
        <h2 v-html="content[theErrorCode].description"></h2>
      </div>
    </template>
    <app-container text>
      <template v-if="theErrorCode === 404">
        <app-card class="u-mb--500">
          <search-form />
        </app-card>
        <p>Pokud jste si jisti, že máte správnou webovou adresu, ale přesto došlo k chybě,
          <nuxt-link to="/komora/o-komore/kontakt">kontaktujte nás.</nuxt-link>
        </p>
        <p>Děkujeme.</p>
      </template>
      <template v-if="theErrorCode === 500">
        <p>
          <app-button text @click="handleError">Přejděte na úvod</app-button> nebo nás
          <app-button text to="/komora/o-komore/kontakt">kontaktujte</app-button>.
        </p>
      </template>
      <template v-if="theErrorCode === 401 && !isAuthenticated">
        <app-grid :columns="2">
          <app-card>
            <login-form />
          </app-card>
        </app-grid>
      </template>
    </app-container>
  </app-page>
</template>
