<script setup lang="ts">
  import type { FormKitContext } from '@formkit/core'
  import { token } from '@formkit/utils'

  const props = defineProps<{ context: FormKitContext }>()

  const inputValue = ref(props.context._value || props.context.multiple ? [] : '')
  const images = ref([])
  const error = ref('')
  const img = useImage()

  watch(() => props.context._value, (value) => {
    if (value.length === 0) {
      images.value = []
      inputValue.value = props.context.multiple ? [] : ''
    }
  })

  if (props.context.multiple) {
    showPreviews(props.context.value)
    inputValue.value = props.context.value.filter(item => !!item)
  } else {
    if (props.context.value) {
      showPreviews([props.context.value])
    }
    inputValue.value = props.context.value
  }

  async function onAdd (event: Event) {
    error.value = ''
    const target = event.target as HTMLInputElement
    const files = [...target.files]
    const validExtensions = ['jpg', 'jpeg', 'png']
    const validFiles = files.filter((file) => {
      const extension = file.name.split('.').pop().toLowerCase()
      return validExtensions.includes(extension)
    })

    if (validFiles.length !== files.length) {
      error.value = 'Prosím nahrávejte pouze obrázky ve formátu JPG nebo PNG'
    }

    const hasErrors = files.find(file => file.size > 25000000)

    if (hasErrors) {
      error.value = 'Maximální velikost souboru je 25 MB'
      return
    }

    await Promise.all(validFiles.map(async (file) => {
      const dataUrl = await readFile(file)

      const base64String = dataUrl
        .replace('data:', '')
        .replace(/^.+,/, '')

      if (props.context.multiple) {
        inputValue.value.push({
          data: base64String,
          encoding: 'base64',
          filename: file?.name,
          'content-type': file?.type,
        })
      } else {
        inputValue.value = {
          data: base64String,
          encoding: 'base64',
          filename: file?.name,
          'content-type': file?.type,
        }
      }
    }))
    props.context.node.input(inputValue.value)
    showPreviews(validFiles)
    event.target.value = ''
    return
  }

  function showPreviews (files) {
    if (files.length > 0) {
      images.value = [...images.value, ...files?.map(file => (file.download
        ? {
          src: img(file.download, { width: 420, height: 315, fit: 'cover', format: 'webp' }),
          alt: file.filename,
        }
        : {
          src: URL.createObjectURL(file),
          alt: file.name,
        }))]
    }
  }

  function removeImage (index) {
    images.value.splice(index, 1)
    if (props.context.multiple) {
      inputValue.value.splice(index, 1)
      props.context.node.input(inputValue.value)
    } else {
      inputValue.value = ''
    }
  }

  function readFile (file: File): Promise<string | ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = () => {
        resolve(reader.result)
      }
      reader.onerror = reject
      reader.readAsDataURL(file)
    })
  }
</script>

<template>
  <div :class="['input-image', { 'input-image--multiple': context.multiple }]">
    <drop-zone
      v-if="context.multiple || images.length === 0"
      :disabled="context.disabled"
      class="input-image__dropzone"
      accept=".jpg,.jpeg,.png"
      :multiple="context.multiple"
      type="image"
      :id="context.id"
      :name="context.name"
      @input="onAdd"/>
    <div v-auto-animate>
      <div
        v-if="images"
        v-auto-animate
        :class="['input-image__preview', { 'u-pt--400': context.multiple }]">
        <figure
          v-for="({ src, alt }, key) in images"
          class="input-image__preview-image">
          <app-button
            class="input-image__remove"
            @click="removeImage(key)"
            :disabled="context.disabled"
            circle>
            <mdicon name="close" size="18"/>
          </app-button>
          <img
            :src="src"
            :alt="alt"/>
        </figure>
      </div>

      <app-alert
        v-if="error"
        class="u-mt--400"
        error>
        {{ error }}
      </app-alert>
    </div>
  </div>
</template>

<style scoped>
  .input-image {
    width: 100%;
    max-width: 768px;
    & :deep(.app-form__fileRemove),
    & :deep(.app-form__fileList) {
      display: none;
    }
  }

  .input-image__preview {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--gap-400);
  }

  .input-image--multiple .input-image__preview {
    grid-template-columns: repeat(3, 1fr);
  }

  .input-image__preview-image {
    margin: 0;
    aspect-ratio: 4/3;
    overflow: hidden;
    position: relative;
    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .input-image__remove {
    position: absolute;
    right: var(--gap-100);
    top: var(--gap-100);
    width: 32px;
    height: 32px;
    & :deep(.app-button__text) {
      padding-top: 0;
      line-height: 0;
    }
  }
</style>
