import VueGtag from 'vue-gtag-next'

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig()
  const hasConsent = useCookie('cka_consent')

  nuxtApp.vueApp.use(VueGtag, {
    property: {
      id: hasConsent.value
        && (hasConsent.value.split(',').includes('gtag') || hasConsent.value.split(',').includes('all'))
        ? config.public.gtagId
        : null,
      isEnabled: false,
    },
  })
})
