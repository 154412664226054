export const usePasswordInput = () => {
  const icon = ref('eyeClosed')
  const inputType = ref('password')

  function toggleVisibility () {
    if (inputType.value === 'password') {
      inputType.value = 'text'
      icon.value = 'eye'
    } else {
      inputType.value = 'password'
      icon.value = 'eyeClosed'
    }
  }

  return {
    icon,
    inputType,
    toggleVisibility
  }
}
