<script setup lang="ts">
  const props = defineProps<{ modelValue: boolean }>()
  const emit = defineEmits(['update:modelValue', 'close'])
  const isOpened = computed({
    get: () => props.modelValue,
    set: value => emit('update:modelValue', value)
  })
</script>

<template>
  <Teleport to="body">
    <div
      v-if="isOpened"
      class="app-modal">
      <app-container>
        <div class="app-modal__content" v-motion-pop>
          <div class="app-modal__inner">
            <slot/>
          </div>
        </div>
      </app-container>
      <div class="app-modal__backdrop" v-motion-fade></div>
    </div>
  </Teleport>
</template>

<style scoped>
  .app-modal {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    overscroll-behavior: contain;
  }

  .app-modal__content {
    position: relative;
    z-index: 1001;
    height: var(--vh-100);
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
    display: flex;
    padding: 0;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: var(--c-gray-200);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--c-text-light);
    }
  }

  .app-modal__inner {
    margin: auto;
    padding: var(--gap-600) 0;
  }

  .app-modal__backdrop {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(240, 240, 240, 0.7);
    backdrop-filter: blur(4px);
    z-index: 1000;
  }
</style>
