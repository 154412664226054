export function useLocaleConfig () {
  const app = useNuxtApp()
  const config = useRuntimeConfig()
  const locale = app.$i18n.locale.value

  return {
    ...config.public,
    baseURL: config.public.baseURL[locale],
    ploneUrl: config.public.ploneUrl[locale],
    apiUrl: config.public.apiUrl[locale],
  }
}
