import { defineNuxtPlugin } from '#app'
import { ObserveVisibility } from 'vue-observe-visibility'

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('observe-visibility', {
    beforeMount: (el, binding, vnode) => {
      (vnode as any).context = binding.instance
      ObserveVisibility.bind(el, binding, vnode)
    },
    updated: ObserveVisibility.update,
    unmounted: ObserveVisibility.unbind,
  })
})
