<script setup lang="ts">
  import hasSlotContent from '~/utils/has-slot-content'

  const props = withDefaults( defineProps<{
    block?: boolean
    primary?: boolean
    square?: boolean
    circle?: boolean
    small?: boolean
    light?: boolean
    white?: boolean
    disabled?: boolean
    destructive?: boolean
    text?: boolean
    loading?: boolean
    to?: string | object
    external?: boolean
    type?: 'submit' | 'button' | 'reset'
  }>(), { type: 'button' })

  const emit = defineEmits(['click'])

  const app = useNuxtApp()
  const slots = useSlots()

  const hasIcon = hasSlotContent(slots.icon)
  const hasText = hasSlotContent(slots.default)
  const clicked = ref(false)

  const onClick = () => {
    emit('click')
    clicked.value = true
  }

  const onHover = () => {
    clicked.value = false
  }
</script>

<template>
  <nuxt-link
    v-if="props.to"
    :to="to"
    :disabled="disabled"
    @click="onClick"
    @mouseenter="onHover"
    @focusin="onHover"
    :external="external"
    :class="[
      'app-button',
      {
        'app-button--primary': props.primary,
        'app-button--destructive': props.destructive,
        'app-button--disabled': props.disabled,
        'app-button--loading': props.loading,
        'app-button--text': props.text,
        'app-button--block': props.block,
        'app-button--light': props.light,
        'app-button--square': props.square,
        'app-button--circle': props.circle,
        'app-button--small': props.small,
        'app-button--white': props.white,
        'app-button--icon': hasIcon && hasText,
        'app-button--icon-only': hasIcon && !hasText,
        'app-button--clicked': clicked,
      },
    ]">
    <span class="app-button__content">
      <span
        v-if="hasText"
        class="app-button__text">
        <slot></slot>
      </span>
      <span
        v-if="hasIcon"
        class="app-button__icon">
        <slot name="icon"></slot>
      </span>
    </span>
    <div
      v-if="loading"
      class="app-button__progress">
      <app-progress/>
    </div>
  </nuxt-link>
  <button
    v-else
    :to="to"
    :disabled="disabled"
    :type="type"
    @click="$emit('click')"
    :class="[
    'app-button',
      {
        'app-button--primary': props.primary,
        'app-button--disabled': props.disabled,
        'app-button--loading': props.loading,
        'app-button--text': props.text,
        'app-button--block': props.block,
        'app-button--light': props.light,
        'app-button--square': props.square,
        'app-button--circle': props.circle,
        'app-button--small': props.small,
        'app-button--destructive': props.destructive,
        'app-button--white': props.white,
        'app-button--icon': hasIcon && hasText,
        'app-button--icon-only': hasIcon && !hasText,
        'app-button--clicked': clicked,
      },
    ]">
    <span class="app-button__content">
     <span
        v-if="hasText"
        class="app-button__text">
        <slot></slot>
      </span>
      <span
        v-if="hasIcon"
        class="app-button__icon">
        <slot name="icon"></slot>
      </span>
    </span>
    <div
      v-if="loading"
      class="app-button__progress">
      <app-progress/>
    </div>
  </button>
</template>

<style scoped>
  .app-button {
    display: inline-flex;
    align-items: center;
    position: relative;
    box-sizing: border-box;
    padding: calc(var(--gap-250) - 1px) var(--gap-400);
    @media (--xs) {
      padding: calc(var(--gap-300) - 1px) var(--gap-400);
    }
    white-space: nowrap;
    background: var(--c-white);
    color: var(--c-primary);
    text-decoration: none;
    font-family: var(--font-family);
    font-size: var(--font-size-400);
    line-height: 1;
    font-weight: 400;
    box-shadow: none;
    text-align: center;
    cursor: pointer;
    box-sizing: border-box;
    transition: color var(--s-fast), background var(--s-fast);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: solid var(--gap-50) var(--c-primary);
    max-width: 100%;

    &:focus:not(.app-button--clicked),
    &:hover {
      outline: none;
      text-decoration: none;
      background: var(--c-primary);
      color: var(--c-white);
      &:deep(.app-icon) {
        filter: brightness(0) invert(1);
      }
    }
  }

  .app-button--small {
    font-size: var(--font-size-300);
    padding: calc(var(--gap-150) - 2px) var(--gap-200);
    @media (--xs) {
      padding: calc(var(--gap-150) - 1px) var(--gap-200);
    }
  }

  .app-button--square {
    aspect-ratio: 1/1;
    width: var(--gap-550);
    padding: 0;
    align-items: center;
    justify-content: center;
    @media (--sm) {
      width: var(--gap-500);
      padding: 0;
    }
  }

  .app-button--circle {
    aspect-ratio: 1/1;
    width: var(--gap-550);
    padding: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    @media (--sm) {
      width: var(--gap-500);
      padding: 0;
    }
  }

  .app-button--small.app-button--circle {
    width: var(--gap-400);
  }

  .app-button--light {
    border: solid 1px var(--c-gray-300);
  }

  .app-button--block {
    display: flex;
    width: 100%;
    text-align: center;
  }

  .app-button--primary {
    background: var(--c-primary);
    color: var(--c-white);
    text-decoration: none;

    &:focus:not(.app-button--clicked),
    &:hover {
      background: var(--c-white);
      color: var(--c-primary);
    }
  }

  .app-button--destructive {
    color: var(--c-error);
    border-color: var(--c-error);

    &:focus:not(.app-button--clicked),
    &:hover {
      background: var(--c-error);
      color: var(--c-white);
    }
  }

  .app-button--text {
    border: 0;
    text-decoration: underline;
    padding: 0 !important;
    background: transparent;
    &:focus:not(.app-button--clicked),
    &:hover {
      background: transparent;
      color: var(--c-primary);
    }
  }

  .app-button--white {
    border: 0;
    text-decoration: underline;
  }

  .app-button--disabled {
    background: var(--c-gray-300);
    border-color: var(--c-gray-300);
    color: var(--c-text);
    cursor: default;
    text-decoration: none;

    &:active,
    &:hover,
    &:focus {
      background: var(--c-gray-300);
      color: var(--c-text);
    }
  }

  .app-button__content {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    transition: opacity var(--fast);
    z-index: 10;
  }

  .app-button--loading .app-button__content {
    opacity: 0;
  }

  .app-button__text {
    flex-grow: 1;
    position: relative;
    z-index: 10;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 2px;
  }

  .app-button__progress {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding-top: 2px;
  }

  .app-button--icon {
    padding-right: var(--gap-250);
    & .app-button__text {
      padding-right: calc(var(--gap-400) + var(--gap-250));
    }
  }

  .app-button__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    aspect-ratio: 1/1;
  }

  .app-button--small.app-button--icon {
    padding-right: var(--gap-150);
    & .app-button__text {
      padding-right: calc(var(--gap-400) + var(--gap-150));
    }
  }
</style>
