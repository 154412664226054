import { makeLinksRelative } from '~/utils/links'

interface Keyable {
  [key: string]: any
}

export const usePloneData = (path: string, options?: { params?: Keyable, pick?: string[] }) => {
  const { authCookieName, apiUrl, ploneUrl } = useLocaleConfig()
  const token = useCookie(authCookieName)
  const nuxtApp = useNuxtApp()
  const authHeaders = token.value
    ? { Authorization: `Bearer ${token.value}` }
    : null

  const headers = {
    Accept: 'application/json',
    'Accept-Language': 'cs',
    ...authHeaders,
  }

  const key = options?.params
    ? `${path}?${new URLSearchParams(options?.params)}`
    : path

  return useFetch(path, {
    headers,
    baseURL: apiUrl,
    key,
    ...options,
    onResponse ({ response }) {
      return makeLinksRelative(response._data, ploneUrl)
    },
  })
}
