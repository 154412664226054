import { useNuxtApp, useCookie } from '#app'
import { jwtDecode } from 'jwt-decode'
import getInitials from '~/utils/get-initials'
import type { Ref } from 'vue'
import type { PloneUser } from '~/types/Plone'
import { useLocaleConfig } from '~/composables/useLocaleConfig'

interface PloneLoginData {
  token: string
}

interface PloneTokenData {
  sub: string
  fullname: string
}

interface AuthOptions {
  redirectTo?: string | 'profile'
}

export default function (options: AuthOptions = {}) {
  const { apiUrl, authCookieName } = useLocaleConfig()
  const router = useRouter()
  const token = useCookie(authCookieName)
  const isAuthenticated =  computed(() => !!token.value)
  const pending = ref(false)
  const errored = ref(false)
  const error = ref('')
  const resetSuccess = ref(false)
  const updatePasswordPending = ref(false)
  const updatePasswordSuccess = ref(false)
  const updatePasswordErrored = ref(false)

  const user = computed(() => {
    if (!token.value) {
      return
    }
    const tokenData: PloneTokenData = jwtDecode(token.value)

    const fullname = tokenData.fullname ? tokenData.fullname : tokenData.sub
    return {
      username: tokenData.sub,
      fullname,
      initials: getInitials(fullname),
    }
  }) as Ref<PloneUser>

  const login = async (login: string, password: string) => {
    pending.value = true
    try {
      const data: PloneLoginData = await $fetch('/@login', {
        baseURL: apiUrl,
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: {
          email: login,
          password,
        }
      })
      token.value = data?.token
      pending.value = false
      if (options.redirectTo) {
        if (options.redirectTo === 'profile') {
          const userData = await $fetch(`/@users/${user.value.username}`, {
            baseURL: apiUrl,
            method: 'GET',
            headers: {
              Accept: 'application/json',
              Authorization: `Bearer ${token.value}`,
            },
          })
          if (userData?.profile_path) {
            window.location = useRelativeLink(userData.profile_path)
          } else {
            window.location = '/'
          }
        } else {
          window.location = options.redirectTo
        }
      } else {
        router.go(0)
      }
    } catch (e) {
      pending.value = false
      errored.value = true
      error.value = e.data?.error?.message || e.message
    }
  }

  const resetPassword = async (login: string) => {
    pending.value = true
    try {
      const data: PloneLoginData = await $fetch(`/@users/${login}/reset-password`, {
        baseURL: apiUrl,
        method: 'POST',
        headers: { Accept: 'application/json' },
      })
      pending.value = false
      resetSuccess.value = true
    } catch (e) {
      pending.value = false
      errored.value = true
      error.value = e.data?.error?.message || e.message
      resetSuccess.value = false
    }
  }

  const updatePassword = async (token: string, login: string, password: string) => {
    updatePasswordPending.value = true
    try {
      await $fetch(`/@users/${login}/reset-password`, {
        baseURL: apiUrl,
        method: 'POST',
        headers: { Accept: 'application/json' },
        body: {
          reset_token: token,
          new_password: password,
        }
      })
      updatePasswordPending.value = false
      updatePasswordSuccess.value = true
    } catch (e) {
      updatePasswordPending.value = false
      updatePasswordErrored.value = true
      updatePasswordSuccess.value = false
      error.value = e.data?.error?.message || e.message
    }
  }

  const logout = () => {
    token.value = null
    router.go(0)
  }

  return {
    isAuthenticated,
    token,
    login,
    logout,
    resetPassword,
    resetSuccess,
    user,
    pending,
    errored,
    error,
    updatePassword,
    updatePasswordPending,
    updatePasswordSuccess,
    updatePasswordErrored
  }
}
