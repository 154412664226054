<script setup lang="ts">
  import { useMotions } from '@vueuse/motion'
  import { useElementBounding } from '@vueuse/core'
  import { useFocusWithin } from '@vueuse/core'
  import type { PloneNavigationItem } from '~/types/Plone'
  import { formatText } from '~/utils/format-text'
  import { useHoverIntent } from 'vue-use-hover-intent'

  const props = defineProps<{ item: PloneNavigationItem }>()

  const route = useRoute()
  const menuItem = ref()
  const handle = ref()
  const dropdown = ref()

  const hovered = useHoverIntent(menuItem)
  const { focused } = useFocusWithin(menuItem)

  const isOpened = ref(false)

  watch (hovered, (hovered) => {
    isOpened.value = hovered
  })

  watch (focused, (focused) => {
    isOpened.value = focused
  })

  const isActive = computed(() => route.path.startsWith(props.item['@id']))
  const indexOfLongestMenu = props.item?.items?.reduce((p, c, i, a) => a[p].items.length > c.items.length ? p : i, 0)
  const { width: handleWidth, height: handleHeight } = useElementBounding(handle)
  const { width: dropdownWidth } = useElementBounding(dropdown)
</script>

<template>
  <li
    ref="menuItem"
    :class="['mega-menu-item', { 'mega-menu-item__opened': isOpened }]">
    <nuxt-link
      ref="handle"
      :class="['mega-menu-item__handle', { 'mega-menu-item__handle--active': isActive }]"
      @click.native="isOpened = false"
      :to="item['@id']">
      {{ item.title }}
      <client-only>
        <svg
          v-if="item.items?.length > 0"
          class="mega-menu-item__handle-guard"
          xmlns="http://www.w3.org/2000/svg"
          aria-haspopup="menu"
          :style="{ pointerEvents: 'none' }"
          :width="dropdownWidth"
          :height="handleHeight"
          :viewBox="`0 0 ${dropdownWidth} ${handleHeight}`">
          <path
            fill="#262626"
            :style="{ pointerEvents: 'all' }"
            :d="`
            M${(dropdownWidth - handleWidth) / 2},0
            H${(dropdownWidth - handleWidth) / 2 + handleWidth}
            Q${(dropdownWidth - handleWidth) / 2 + handleWidth},${handleHeight} ${dropdownWidth},${handleHeight}
            H0
            Q${(dropdownWidth - handleWidth) / 2},${handleHeight} ${(dropdownWidth - handleWidth) / 2},0
            Z`"/>
        </svg>
      </client-only>
    </nuxt-link>
    <template v-if="item.items?.length > 0">
      <transition name="mega-menu-item-fade">
        <div
          v-show="item.items && isOpened"
          class="mega-menu-item__dropdown">
          <app-container>
            <ul
              ref="dropdown"
              :class="[
                'mega-menu-item__layout',
                `mega-menu-item__layout--${item.items.length}`,
                'u-list-unstyled'
              ]">
              <li
                v-for="(item, index) in item.items"
                :class="['mega-menu-item__sub-menu', { 'mega-menu-item__sub-menu--long': indexOfLongestMenu === index }]">
                <nuxt-link
                  tabindex="0"
                  class="mega-menu-item__sub-menu-title"
                  @click.native="isOpened = false"
                  :to="item['@id']">
                  {{ formatText(item.title) }}
                </nuxt-link>
                <ul
                  v-if="item.items"
                  class="u-list-unstyled">
                  <li v-for="item in item.items">
                    <nuxt-link
                      tabindex="0"
                      class="mega-menu-item__link"
                      @click.native="isOpened = false"
                      :to="item['@id']">
                      {{ formatText(item.title) }}&nbsp;<span class="mega-menu-item__link-arrow">></span>
                    </nuxt-link>
                  </li>
                </ul>
              </li>
            </ul>
          </app-container>
        </div>
      </transition>
    </template>
  </li>
</template>

<style scoped>
  .mega-menu-item {
    & a {
      text-decoration: none;
      color: var(--c-text);
      font-size: var(--font-size-400);
      transition: color var(--s-fast);

      &:hover {
        color: var(--c-primary);

        & .mega-menu-item__link-arrow {
          opacity: 1;
          transform: translateX(0);
        }
      }

      &.router-link-active {
        color: var(--c-primary);
        text-decoration: underline;
      }
    }
  }

  .mega-menu-item__link-arrow {
    display: inline-block;
    opacity: 0;
    transform: translateX(-50%);
    transition: var(--s-fast);
  }

  .mega-menu-item__opened > a {
    color: var(--c-primary);
  }

  .mega-menu-item__opened .mega-menu-item__dropdown {
    z-index: 100;
  }

  .mega-menu-item__dropdown {
    width: 100%;
    padding-top: var(--gap-400);
    padding-bottom: var(--gap-500);
    position: absolute;
    left: 0;
    top: var(--gap-600);
    z-index: -1;
    background: var(--c-background-light);
    border-bottom: 1px solid var(--c-text-light);
    max-height: calc(var(--vh-100) - var(--gap-600));
    overflow: hidden;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track-piece {
      background-color: var(--c-gray-200);
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--c-text-light);
    }
  }

  .mega-menu-item__layout {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--gap-400);
  }

  .mega-menu-item__layout--2 {
    grid-template-columns: 1fr 1fr;
  }

  .mega-menu-item__layout--2 .mega-menu-item__sub-menu {
    & ul {
      column-count: 2;
      column-gap: var(--gap-400);
      margin: 0;
    }
  }

  .mega-menu-item__layout--3 .mega-menu-item__sub-menu--long {
    grid-column: span 2;

    & ul {
      column-count: 2;
      column-gap: var(--gap-400);
    }
  }

  .mega-menu-item__handle {
    padding: var(--gap-350);
    @media (--xl) {
      padding: var(--gap-400);
    }
    position: relative;
    display: flex;
    white-space: nowrap;
    align-items: center;
    line-height: 1;
  }

  .mega-menu-item__handle-guard {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: var(--gap-350);
    z-index: 100;

    & path {
      fill: transparent;
    }
  }

  .mega-menu-item__handle--active {
    color: var(--c-primary) !important;
    text-decoration: underline !important;
  }

  .mega-menu-item__sub-menu {
    display: block;
  }

  .mega-menu-item__sub-menu-title {
    display: block;
    font-family: var(--font-family-monospace);
    font-size: var(--font-size-250) !important;
    padding: var(--gap-100) 0;
    margin-bottom: var(--gap-200);
    border-bottom: solid var(--gap-50) var(--c-text-light);
  }

  .mega-menu-item__link {
    display: block;
    margin: 0 0 var(--gap-150) 0;
  }


  .mega-menu-item-fade-enter-active,
  .mega-menu-item-fade-leave-active {
    transition: opacity 0.2s ease;
  }

  .mega-menu-item-fade-enter-from,
  .mega-menu-item-fade-leave-to {
    opacity: 0;
  }

  .mega-menu-item-fade-leave-to {
    pointer-events: none;
    transition-delay: 0.2s;
    z-index: -1;
  }
</style>
