import revive_payload_client_gxKetEmiX3 from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_N3XWf15OHk from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_gJkXxzoytS from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_vukQhtpfJy from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.5_rollup@4.19.1_vite@5.3.5_@types+node@22.7.4_terser@5.3_uuzfkg5mi4qxoe57pqkootn3ty/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_6uPJ792Pf3 from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aenjmb4RAg from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_bJ4YgRW0AX from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_O9bcY13YrY from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_lrZedSqW9q from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt@3.12.4_@parcel+watcher@2.4.1_@types+node@22.7.4_eslint@8.57.0_ioredis@5.4.1_magicast@0.3_26zjff4tnpo6diw4dsldwgg554/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_c7P3mitzod from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/nuxt-bugsnag@7.3.0_@bugsnag+core@7.25.0_magicast@0.3.5_rollup@4.19.1/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.mjs";
import i18n_qjZNWiKhbL from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/@nuxtjs+i18n@8.1.1_magicast@0.3.5_rollup@4.19.1_vue@3.4.34_typescript@5.3.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import motion_wfVG0taFFx from "/home/grunghi/projects/cka-frontend/node_modules/.pnpm/@vueuse+motion@2.1.0_magicast@0.3.5_rollup@4.19.1_vue@3.4.34_typescript@5.3.3_/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import formkitPlugin_pZqjah0RUG from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/.nuxt/formkitPlugin.mjs";
import autoAnimate_4HQGapz9xs from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/autoAnimate.ts";
import gtag_client_Zw8EQXNVTz from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/gtag.client.ts";
import mdijs_BLSoYUWyMD from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/mdijs.ts";
import plone_UkIciQOizT from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/plone.ts";
import vueEasyLightbox_uNpOtA28HR from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueEasyLightbox.ts";
import vueGoogleMaps_8xmoAwKZvr from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueGoogleMaps.ts";
import vueObserveVisibility_u8X0s2VLui from "/home/grunghi/projects/cka-frontend/.temp/cka-frontend/plugins/vueObserveVisibility.ts";
export default [
  revive_payload_client_gxKetEmiX3,
  unhead_N3XWf15OHk,
  router_gJkXxzoytS,
  _0_siteConfig_vukQhtpfJy,
  payload_client_6uPJ792Pf3,
  navigation_repaint_client_aenjmb4RAg,
  check_outdated_build_client_bJ4YgRW0AX,
  chunk_reload_client_O9bcY13YrY,
  components_plugin_KR1HBZs4kY,
  prefetch_client_lrZedSqW9q,
  plugin_c7P3mitzod,
  i18n_qjZNWiKhbL,
  motion_wfVG0taFFx,
  formkitPlugin_pZqjah0RUG,
  autoAnimate_4HQGapz9xs,
  gtag_client_Zw8EQXNVTz,
  mdijs_BLSoYUWyMD,
  plone_UkIciQOizT,
  vueEasyLightbox_uNpOtA28HR,
  vueGoogleMaps_8xmoAwKZvr,
  vueObserveVisibility_u8X0s2VLui
]