<script setup lang="ts">
  const props = defineProps<{ images: string[] | string }>()

  const openedImage = ref(null)
  const isOpened = ref(false)

  const open = index => {
    isOpened.value = true
    openedImage.value = index
  }

  const hasPrevNext = computed(() => {
    return Array.isArray(props.images)
      ? props.images.length > 1
      : false
  })
</script>

<template>
  <div class="app-lightbox">
    <slot :open="open"/>
    <vue-easy-lightbox
      ref="lightbox"
      class="app-lightbox__box"
      scroll-disabled
      :visible="isOpened"
      :imgs="images"
      :index="openedImage"
      :zoom-scale="1"
      :max-zoom="6"
      @hide="isOpened = false">
      <template #prev-btn="{ prev }">
        <app-button
          v-if="hasPrevNext"
          class="app-lightbox__prev"
          @click="prev"
          white
          square>
          <mdicon
            name="chevronLeft"
            size="42"/>
        </app-button>
      </template>

      <template #next-btn="{ next }">
        <app-button
          v-if="hasPrevNext"
          class="app-lightbox__next"
          @click="next"
          white
          square>
          <mdicon
            name="chevronRight"
            size="42"/>
        </app-button>
      </template>

      <template #close-btn="{ close }">
        <app-button
          @click="close"
          white
          class="app-lightbox__close"
          square>
          <mdicon
            name="close"
            size="30"/>
        </app-button>
      </template>

      <template #toolbar="{ toolbarMethods: { zoomIn, zoomOut } }">
        <div class="app-lightbox__toolbar">
          <app-button
            @click="zoomOut"
            white
            class="app-lightbox__zoom-out"
            square>
            <mdicon name="magnify-minus-outline"/>
          </app-button>
          <app-button
            @click="zoomIn"
            white
            class="app-lightbox__zoom-in"
            square>
            <mdicon name="magnify-plus-outline"/>
          </app-button>
        </div>
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<style scoped>
  .app-lightbox:deep(.vel-img) {
    box-shadow: none !important;
  }

  .app-lightbox__box {
    background-color: var(--c-white);
  }

  .app-lightbox__prev {
    position: absolute;
    left: var(--gap-300);
    top: 50%;
    transform: translateY(-50%);
  }

  .app-lightbox__next {
    position: absolute;
    right: var(--gap-300);
    top: 50%;
    transform: translateY(-50%);
  }

  .app-lightbox__close {
    position: absolute;
    right: var(--gap-300);
    top: var(--gap-300);
  }

  .app-lightbox__toolbar {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: var(--gap-400);

    &:deep(.app-button) {
      margin: 0 var(--gap-200);
    }
  }
</style>
