import { defineNuxtPlugin } from '#app'
import { navigateTo, useCookie, useRuntimeConfig, useState, useI18n } from '#imports'
import type { NitroFetchRequest } from 'nitropack'
import type { PloneQueryParams } from '~/types/Plone'
import { makeLinkRelative, makeLinksRelative } from '~/utils/links'
import { useLocaleConfig } from '~/composables/useLocaleConfig'

export default defineNuxtPlugin(async nuxtApp => {
  const { ploneUrl, apiUrl, authCookieName } = useLocaleConfig()
  const token = useCookie(authCookieName)
  const baseURL = ploneUrl
  const authHeaders = token.value
    ? { Authorization: `Bearer ${token.value}` }
    : null

  const headers = {
    Accept: 'application/json',
    'Accept-Language': 'cs',
    ...authHeaders,
  }

  return {
    provide: {
      plone: async (path: NitroFetchRequest, options?: PloneQueryParams, controller?: AbortController) => {
        try {
          const res = await $fetch.raw(path, {
            ...options,
            baseURL: apiUrl,
            headers,
            signal: controller?.signal,
          })

          const data = res?._data
          if (res.redirected) {
            return {
              redirectTo: makeLinkRelative(res.url, baseURL).split('?')[0],
              ...makeLinksRelative(data, baseURL),
            }
          }

          return makeLinksRelative(data, baseURL)
        } catch (e) {
          console.error(e)
          if (!controller?.signal?.aborted) {
            throw new Error(e)
          }
        }
      },
      relativeLink: link => makeLinkRelative(link, baseURL),
    },
  }
})

declare module '#app' {
  interface NuxtApp {
    $plone (path: string, options?: Object, controller?: AbortController): Promise<any>
  }
}
