
import * as cloudflareRuntime$fYMIOidtcn from '/home/grunghi/projects/cka-frontend/node_modules/.pnpm/@nuxt+image@1.7.0_ioredis@5.4.1_magicast@0.3.5_rollup@4.19.1/node_modules/@nuxt/image/dist/runtime/providers/cloudflare'

export const imageOptions = {
  "screens": {
    "xs": 420,
    "sm": 600,
    "md": 980,
    "lg": 1264,
    "xl": 1904,
    "xxl": 2560,
    "2xl": 1536
  },
  "presets": {},
  "provider": "cloudflare",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['cloudflare']: { provider: cloudflareRuntime$fYMIOidtcn, defaults: {"baseURL":"https://www.cka.cz"} }
}
        