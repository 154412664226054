<script setup lang="ts">
  import { useToggle } from '@vueuse/core'
  import { useState } from 'vue-gtag-next'

  interface CookieOption {
    label: string,
    name: string,
    value?: boolean
  }

  interface CookiePreferencesSection {
    title: string
    description?: string
    items: CookieOption[]
  }

  const props = withDefaults(defineProps<{
    panelTitle?: string,
    panelDescription?: string,
    modalTitle?: string,
    acceptAllLabel?: string,
    preferencesLabel?: string,
    saveLabel?: string,
    closeLabel?: string,
    rejectAllLabel?: string,
    cookiePreferences?: CookiePreferencesSection[]
  }>(), {
    panelTitle: 'Nastavení cookies',
    panelDescription: 'Používáme soubory cookie a podobné technologie, které pomáhají personalizovat obsah a nabízet lepší zážitek. Přizpůsobte si je kliknutím na tlačítko nastavení.',
    modalTitle: 'Nastavení soukromí',
    preferencesLabel: 'Nastavení',
    acceptAllLabel: 'Povolit vše',
    saveLabel: 'Uložit nastavení',
    closeLabel: 'Zavřít',
    rejectAllLabel: 'Odmítnout vše',
  })

  const emit = defineEmits(['close'])
  const config = useRuntimeConfig()
  const hasConsent = useCookie('cka_consent')
  const { property } = useState()
  const [isModalOpened, toggleModal] = useToggle()
  const data = ref({
    gtag: true,
  })

  function saveConsent () {
    hasConsent.value = Object.keys(data.value).join(',')
    if (data.value.gtag) {
      enableGA()
    }
  }

  function acceptAll () {
    enableGA()
    hasConsent.value = 'all'
  }

  function rejectAll () {
    hasConsent.value = 'none'
  }

  function enableGA () {
    property.value = {
      id: config.public.gtagId,
    }
  }
</script>

<template>
  <div class="cookie-consent" v-if="!hasConsent">
    <div class="cookie-consent__panel">
      <div class="cookie-consent__panel-content">
        <h2
          v-if="panelTitle"
          class="u-fs--400 u-mb--0">
          {{ panelTitle }}
        </h2>
        <p
          v-if="panelDescription"
          class="u-fs--200 u-mb--0">{{ panelDescription }}</p>
      </div>
      <div class="cookie-consent__panel-actions">
        <app-button light @click="toggleModal(true)">
          {{ preferencesLabel }}
        </app-button>
        <app-button
          @click="acceptAll"
          primary>
          {{ acceptAllLabel }}
        </app-button>
      </div>
    </div>

    <app-modal
      v-model="isModalOpened">
      <div class="cookie-consent__modal">
        <app-card>
          <h2 class="u-fs--600 u-mb--400">
            {{ modalTitle }}
          </h2>
          <div class="cookie-consent__modal-content u-mb--400">
            <section
              v-for="section in cookiePreferences"
              class="cookie-consent__section">
              <h3 class="cookie-consent__section-title u-fs--500 u-mb--300">
                {{ section.title }}
              </h3>
              <p v-if="section.description" class="u-fs--300">
                {{ section.description }}
              </p>
              <app-form
                v-model="data"
                :schema="section.items.map(cookie => ({ $formkit: 'checkbox', ...cookie }))"/>
            </section>
          </div>
          <div class="cookie-consent__modal-actions">
            <app-button
              primary
              @click="saveConsent">
              {{ saveLabel }}
            </app-button>
            <app-button
              light
              @click="rejectAll">
              {{ rejectAllLabel }}
            </app-button>
            <app-button
              text
              @click="toggleModal(false)"
              class="cookie-consent__modal-close">{{ closeLabel }}
            </app-button>
          </div>
        </app-card>
      </div>
    </app-modal>
  </div>
</template>

<style scoped>
  .cookie-consent__panel {
    display: grid;
    @media (--sm) {
      grid-template-columns: 1fr minmax(30%, 35%);
    }
    grid-gap: var(--gap-200);
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1500;
    background: var(--c-background-light);
    border-top: 1px solid var(--c-text-light);
    padding: var(--gap-200) var(--gap-300);
  }

  .cookie-consent__panel-actions {
    display: grid;
    grid-gap: var(--gap-200);
    grid-template-columns: 1fr 1fr;
  }

  .cookie-consent__modal-actions {
    align-items: center;
    justify-content: center;
    display: flex;
    gap: var(--gap-300);
    flex-direction: column;
    @media (--sm) {
      flex-direction: row;
    }

    @media (--xs) {
      & :deep(.app-button) {
        width: 100%;
      }
    }
  }

  .cookie-consent__modal-close {
    @media (--sm) {
      margin-right: var(--gap-400);
      margin-left: auto;
    }
  }
</style>
