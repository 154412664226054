import { defineNuxtPlugin } from '#app'
import mdiVue from 'mdi-vue/v3'

import {
  mdiMenu,
  mdiClose,
  mdiCloseCircle,
  mdiMagnify,
  mdiLock,
  mdiPlus,
  mdiEmailOutline,
  mdiPhone,
  mdiCheck,
  mdiExclamation,
  mdiInformationVariant,
  mdiAccountOutline,
  mdiHomeEditOutline,
  mdiAccountEdit,
  mdiChevronLeft,
  mdiChevronRight,
  mdiChevronDown,
  mdiChevronUp,
  mdiPrinterOutline,
  mdiPlaylistEdit,
  mdiCloudDownloadOutline,
  mdiFilterMenuOutline,
  mdiFilterRemoveOutline,
  mdiMagnifyPlusOutline,
  mdiMagnifyMinusOutline,
  mdiMagnifyRemoveOutline,
  mdiInformationOutline,
  mdiCalendarMonthOutline,
  mdiCloudUploadOutline,
  mdiImagePlusOutline,
  mdiContentSaveEditOutline,
  mdiClipboardTextOutline,
  mdiClipboardCheckOutline,
} from '@mdi/js'

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(mdiVue, {
    icons: {
      mdiMenu,
      mdiClose,
      mdiCloseCircle,
      mdiMagnify,
      mdiLock,
      mdiPlus,
      mdiEmailOutline,
      mdiPhone,
      mdiCheck,
      mdiExclamation,
      mdiInformationVariant,
      mdiAccountOutline,
      mdiHomeEditOutline,
      mdiAccountEdit,
      mdiChevronLeft,
      mdiChevronRight,
      mdiChevronDown,
      mdiChevronUp,
      mdiPrinterOutline,
      mdiPlaylistEdit,
      mdiCloudDownloadOutline,
      mdiFilterMenuOutline,
      mdiFilterRemoveOutline,
      mdiMagnifyPlusOutline,
      mdiMagnifyMinusOutline,
      mdiMagnifyRemoveOutline,
      mdiInformationOutline,
      mdiCalendarMonthOutline,
      mdiCloudUploadOutline,
      mdiImagePlusOutline,
      mdiContentSaveEditOutline,
      mdiClipboardTextOutline,
      mdiClipboardCheckOutline,
    }
  })
})
