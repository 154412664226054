<script setup lang="ts">
  import type { FormKitContext } from '@formkit/core'

  const props = defineProps<{ context: FormKitContext }>()

  const inputValue = ref(props.context._value)

  const mapRef = ref()

  const center = ref(inputValue.value
    ? inputValue.value
    : { lat: 49.7438, lng: 15.3387 })

  // delay map render to prevent issues with SSR
  const initialized = ref(false)
  onMounted(() => {
    setTimeout(() => {
      initialized.value = true
    }, 300)
  })

  const handleInput = (data) => {
    props.context.node.parent.value.gps_latitude = `${data.latLng.lat()}`
    props.context.node.parent.value.gps_longitude = `${data.latLng.lng()}`
    const position = {
      lat: data.latLng.lat(),
      lng: data.latLng.lng(),
    }
    inputValue.value = position
    props.context.node.input(position)
  }
</script>

<template>
  <div class="input-map">
    <GMapMap
      v-if="initialized"
      ref="mapRef"
      :style="{ width: '100%', height: '100%'}"
      :center="center"
      @click="handleInput"
      :options="{
        mapId: '71f3e6428898c546',
        mapTypeControl: false,
        streetViewControlOptions: false,
      }"
      :zoom="7">
      <GMapMarker
        v-if="inputValue"
        draggable
        @dragend="handleInput"
        :options="{ position: inputValue || center }"/>
    </GMapMap>
  </div>
</template>

<style scoped>
  .input-map {
    width: 100%;
    aspect-ratio: 16/9;
    max-width: 768px;
  }
</style>
